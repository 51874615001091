<template>
    <div class="fullscreen-popup">
        <a-button ref="openButton" v-modal="popupId" class="open-button" />
        <a-modal :id="popupId" :is-overlay-close="false">
            <s-form-container :form="form" @submit="isSubmitted = true; $emit('submitted')" />
            <a-button
                class="close-button"
                data-micromodal-close
                glyph="close"
                type="inverse"
                aria-label="Close modal"
                @click="$emit('close', isSubmitted)"
            />
        </a-modal>
    </div>
</template>

<script lang="ts">
import AButton from '@core/components/button/button.vue';
import AModal from '@core/components/modal/modal.vue';
import Modal from '@core/directives/modal.js';
import form from '@core/mixins/form.js';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';

export default {
    name: 'FullscreenPopup',

    components: {
        AButton,
        AModal,
        SFormContainer,
    },

    directives: {
        Modal,
    },

    mixins: [form],

    props: {
        data: {
            type: Object as any,
            required: true,
        },

        enabled: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['close', 'submitted'],

    data: () => ({
        form: null,
        isSubmitted: false,
        popupId: 'fullscreen-popup',
    }),
    computed: {
        isCorporate() {
            return this.data.pageAudienceId === 3 || this.data.pageAudienceId === 'corporate';
        },
        isConsumer() {
            return this.data.pageAudienceId === 2 || this.data.pageAudienceId === 'consumer';
        },
    },

    watch: {
        enabled: {
            handler(newValue) {
                if (newValue) this.$refs?.openButton?.$el.click();
            },
            immediate: true,
        },
    },

    beforeMount() {
        if (this.formSpecialOffer?.form) {
            this.form = this.formSpecialOffer.form;

            if (this.data.title) {
                this.form.registration.title = this.data.title;
            }

            if (this.data.ctaPrimaryText) {
                this.form.registration.button.text = this.data.ctaPrimaryText;
            }

            if (this.data.description) {
                this.form.registration.subtitles = [this.data.description];
            }

            if (this.data.formSubmitSuccessMessage) {
                this.form.flow.notification.title = '';
                this.form.flow.notification.description = [this.data.formSubmitSuccessMessage];
            }

            if (this.data.salesforceCampaignId) {
                this.form.flow.marketo.sfdc_campaign_id = this.data.salesforceCampaignId;
            }

            if (this.isCorporate) {
                this.form.flow.marketo.company_type = 'Corporate End-Customer';
                this.form.flow.marketo.product_title = 'Acronis Cyber Protect';
            }

            if (this.isConsumer) {
                this.form.flow.marketo.company_type = 'Prosumer End-Customer';
                this.form.flow.marketo.product_title = 'Acronis True Image';
            }

            this.form.registration.fields = this.form.registration.fields.filter((item: any) => {
                if (this.isCorporate && item.isConsumer) return false;
                if (this.isConsumer && item.isCorporate) return false;
                return true;
            });

            this.form.flow.marketo.utm_term = this.data.id;
        }
    },
};
</script>

<style lang="postcss" scoped>
.fullscreen-popup {
    .open-button {
        display: none;
    }
}

#fullscreen-popup {
    .close-button {
        inset-inline-end: 0;
        top: -40px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border: 1px solid white;
        border-radius: 50%;
        cursor: pointer;

        @media (--viewport-tablet) {
            top: 0;
            inset-inline-end: -48px;
        }

        &:deep(.a-button__glyph) {
            margin: 0;
        }
    }
    &.a-modal {
        &:deep(.close),
        &:deep(.close-mobile) {
            display: none;
        }
        &:deep(.content) {
            width: auto;
        }
    }

    &:deep(.s-form-container) {
        margin: 0;

        @media (--viewport-desktop) {
            width: 648px;
        }
    }

    &:deep(.notification) {
        padding: 96px 90px;
        border-radius: 4px;

        b {
            @mixin title-accent;
            display: block;
            margin: 16px 0 0;
            color: var(--av-nav-primary);
        }

        .a-glyph {
            width: 85px;
            height: 76px;
        }
    }
}
</style>
