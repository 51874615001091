<template>
    <div class="company-pages">
        <div v-if="noJobForSlugError" class="a-container">
            <div class="no-job-for-slug">
                <a-glyph name="error" />
                <span>
                    {{ noJobForSlugError }}
                </span>
            </div>
        </div>
        <section class="s-company-careers">
            <div class="a-container">
                <client-only>
                    <div class="careers-main">
                        <div class="careers-sidebar">
                            <div class="title">
                                {{ sidebarTitle }}
                            </div>
                            <div class="locations">
                                <el-select
                                    v-model="chosenLocations"
                                    filterable
                                    :hide-on-resize="isDesktop"
                                    multiple
                                    size="large"
                                    :placeholder="sidebarTitle"
                                >
                                    <el-option
                                        v-for="item in allLocations"
                                        :key="item.alpha3Code"
                                        :label="item.descriptor"
                                        :value="item.descriptor"
                                    />
                                </el-select>
                            </div>
                            <div v-if="allOpenJobsCount > 0" class="all-jobs-count">
                                {{ allOpenJobsLabel }}: {{ allOpenJobsCount }}
                            </div>
                            <div class="departments">
                                <el-select
                                    v-model="chosenDepartment"
                                    default="all"
                                    size="large"
                                    :hide-on-resize="isDesktop"
                                >
                                    <el-option key="all" value="all" :label="allDepartmentsLabel" />
                                    <template v-if="departments.length">
                                        <el-option
                                            v-for="department in departments"
                                            :key="`option-${department.id}`"
                                            :label="department.value"
                                            :value="department.id"
                                        />
                                    </template>
                                </el-select>
                            </div>
                            <button
                                v-if="departments.length"
                                class="button"
                                :disabled="chosenDepartment === 'all'"
                                @click="chosenDepartment = 'all'"
                            >
                                {{ allDepartmentsText }}
                            </button>
                            <button
                                v-for="department in departments"
                                :key="`button-${department.id}`"
                                class="button"
                                :disabled="chosenDepartment === department.id"
                                @click="chosenDepartment = department.id"
                            >
                                {{ department.value }}
                            </button>
                        </div>
                        <div v-if="jobsByLocation.length" class="careers-content">
                            <div v-if="allOpenJobsCount > 0" class="all-jobs-count">
                                {{ allOpenJobsLabel }}: {{ allOpenJobsCount }}
                            </div>
                            <div class="careers-jobs">
                                <template v-for="(job, index) in jobsToShow">
                                    <div :key="job.id" class="careers-job">
                                        <a-link
                                            class="title"
                                            :to="getJobUrl(job)"
                                            target="_blank"
                                            :text="cutText(job.title, 60)"
                                        />
                                        <div class="locations">
                                            <div class="location">
                                                {{ job?.primaryLocation?.descriptor }}
                                            </div>
                                            <!-- <div v-for="location in job.additionalLocations" :key="location.id" class="location">
                                                {{ location?.country?.descriptor }} - {{ location?.descriptor }}
                                            </div> -->
                                        </div>
                                    </div>
                                    <div
                                        v-if="shouldDrawJobLine(index, jobsByLocation.length)"
                                        :key="`line-${job.url}`"
                                        class="careers-line"
                                    />
                                </template>
                            </div>
                            <a-button
                                v-if="moreButtonShown"
                                class="careers-more"
                                :class="{ active: allItemsShown }"
                                :text="allItemsShown ? showLessLabel : showMoreLabel"
                                glyph="chevron-down"
                                @click="showMore"
                            />
                        </div>
                    </div>
                </client-only>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AButton from '@core/components/button/button.vue';
import ClientOnly from '@core/components/client-only/client-only.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SCompanyPagesCareers',
    components: {
        ALink,
        AButton,
        ClientOnly,
        AGlyph,
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },
    mixins: [breakpoint],
    props: {
        /**
         * All departments title
         */
        allDepartmentsText: {
            type: String,
            default: 'All departments',
        },
        /**
         * Sidebar title
         */
        sidebarTitle: {
            type: String,
            default: 'Select your location',
        },
        /**
         * All departments label
         */
        allDepartmentsLabel: {
            type: String,
            default: 'All departments',
        },
        /**
         * Show more label
         */
        showMoreLabel: {
            type: String,
            default: 'Show all open positions',
        },
        /**
         * 'Show less' button label
         */
        showLessLabel: {
            type: String,
            default: 'Show less open positions',
        },
        /**
         * 'All open jobs' button label
         */
        allOpenJobsLabel: {
            type: String,
            default: 'All open jobs',
        },
        /**
         * Jobs limit
         */
        jobsLimit: {
            type: Number,
            default: 12,
        },
        /**
         * Url part for job page
         */
        jobPageUrlPart: {
            type: String,
            default: '/careers/job/',
        },
        /**
         * Text when redirected from 404 job page
         */
        noJobOfferForSlugText: {
            type: String,
            default: 'No such job offer "$slug", but you can check other jobs',
        },
    },
    data() {
        return {
            country: null,
            chosenLocations: [],
            chosenDepartment: 'all',
            allItemsShown: false,
            loading: true,
        };
    },
    computed: {
        ...mapState({
            jobPostings: (state) => state.workday?.items || [],
        }),

        noJobForSlugError() {
            if (this.$route.query?.error !== '404' || !this.$route.query?.slug) return null;

            return this.noJobOfferForSlugText.replace('$slug', this.$route.query.slug);
        },

        allLocations() {
            if (!this.jobPostings.length) return [];

            const locations = this.jobPostings?.flatMap((job) => [
                job.primaryLocation?.country,
                ...job.additionalLocations?.map((location) => location.country) || [],
            ]).filter(Boolean) || [];

            const codes = locations.map((x) => x.alpha3Code);
            return locations
                .filter((x, i) => codes.indexOf(x.alpha3Code) === i)
                .sort((a, b) => a.descriptor.localeCompare(b.descriptor));
        },

        jobsByLocation() {
            if (!this.chosenLocations.length) return this.jobPostings;

            return this.jobPostings?.filter((job) => {
                const hasPrimary = this.chosenLocations.includes(job.primaryLocation?.country.descriptor);
                const hasAdditional = this.chosenLocations.some((x) => job.additionalLocations?.some((y) => y.country.descriptor === x));
                return hasAdditional || hasPrimary;
            }) || [];
        },

        jobsByDepartment() {
            if (this.chosenDepartment === 'all') return this.jobsByLocation;

            return this.jobsByLocation.filter((x) => x.categories.some((y) => y.id === this.chosenDepartment));
        },

        jobsToShow() {
            return this.allItemsShown ? this.jobsByDepartment : this.jobsByDepartment.slice(0, this.jobsLimit);
        },

        departments() {
            if (!this.jobsByLocation.length) return [];

            const departments = new Map();

            this.jobsByLocation
                .flatMap((job) => job.categories)
                .filter(Boolean)
                .forEach((x) => departments.set(x.id, x.descriptor));

            return [...departments].map(([id, value]) => ({ id, value }));
        },

        allOpenJobsCount() {
            return this.jobsByLocation.length;
        },

        moreButtonShown() {
            return this.jobsByDepartment.length > this.jobsLimit;
        },
    },
    async mounted() {
        await this.authorize();
        await this.tryCatchJobPostings();
        await this.fillGeoData();
    },
    methods: {
        async authorize() {
            await fetch('/public/integrations/workday/authorize');
        },

        async fetchJobPostings() {
            const response = await fetch('/public/integrations/workday/jobPostings');
            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }
            const jobs = await response.json();
            this.$store.dispatch('workday/setJobs', jobs);
            this.loading = false;
        },

        async tryCatchJobPostings() {
            try {
                await this.fetchJobPostings();
            } catch {
                try {
                    await this.authorize();
                    await this.fetchJobPostings();
                } catch {
                    this.loading = false;
                }
            }
        },

        showMore() {
            this.allItemsShown = !this.allItemsShown;
        },

        async fillGeoData() {
            await this.$store.dispatch('geolocation/getGeoLocation');

            this.country = this.$store.state.geolocation?.location?.data?.country?.name || 'United States';

            if (this.allLocations.some((x) => x.descriptor === this.country)) {
                this.chosenLocations = [this.country];
            }
        },

        cutText(str, len) {
            if (typeof str !== 'string') {
                return '';
            }

            if (str.length <= len) {
                return str;
            }

            const text = str.split(' ');
            let result = '';
            let currentLength = 0;

            for (let i = 0; i < text.length; i++) {
                if (currentLength + text[i].length > len) {
                    break;
                } else {
                    currentLength += text[i].length;
                    result += `${text[i]} `;
                }
            }

            return `${result.trim()}...`;
        },

        shouldDrawJobLine(index, length) {
            const breakpoints = {
                desktopLarge: 3,
                desktopWide: 3,
                desktop: 3,
                tablet: 2,
                mobileWide: 1,
                mobile: 1,
            };
            const jobsInLine = breakpoints[this.currentBreakpoint] || 1;

            return (index + 1) % jobsInLine === 0 || index + 1 === length;
        },

        getJobUrl(job) {
            const id = job.url?.split('/').pop().split('_').pop() || '';
            if (!id) return job.url;

            return `${this.jobPageUrlPart}?slug=${id}`;
        },
    },
};
</script>
<style lang="postcss">
.company-pages .s-company-careers {
    @mixin basic-slice-paddings;

    .careers-header {
        display: block;
        width: 100%;
        max-width: 100%;

        @media (--viewport-tablet) {
            @mixin colls 8;
        }
        > .heading {
            @mixin display-accent;
            color: var(--av-nav-primary);
            margin-bottom: 24px;

            @media (--viewport-mobile-wide) {
                @mixin hero-accent;
            }

            @media (--viewport-desktop) {
                @mixin large-accent;
            }
        }
        > .description {
            @mixin lead;
            color: var(--av-fixed-primary);
            @media (--viewport-tablet) {
                @mixin title;
            }
        }
    }

    .careers-main {
        position: relative;

        @media (--viewport-tablet) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            width: 100%;
        }
    }

    .all-jobs-count {
        @mixin lead-accent;
        color: var(--av-fixed-primary);
        margin-bottom: 16px;
    }

    .careers-sidebar {
        width: 100%;

        @media (--viewport-tablet) {
            @mixin colls 4;
        }
        @media (--viewport-desktop) {
            @mixin colls 3;
        }

        > .locations {
            margin-bottom: 32px;
            @media (--viewport-desktop) {
                margin-bottom: 40px;
            }
        }
        > .departments {
            display: block;
            @media (--viewport-tablet) {
                display: none;
            }
        }

        > .all-jobs-count {
            @media (--viewport-tablet) {
                display: none;
            }
        }
        > .title {
            @mixin lead-accent;
            color: var(--av-fixed-primary);
            margin-bottom: 16px;
        }
        > .button {
            @mixin paragraph;
            text-align: start;
            display: none;
            border: none;
            outline: none;
            background: transparent;
            padding: 12px 16px;
            color: var(--av-nav-primary);
            cursor: pointer;
            transition: all 0.2s ease;
            width: 100%;

            @media (--viewport-tablet) {
                display: block;
            }
            &:disabled {
                cursor: default;
                border-radius: 4px;
                background: var(--av-brand-lightest);
            }
            &:hover:enabled {
                color: var(--av-brand-secondary);
            }
        }
    }

    .careers-content {
        width: 100%;
        margin-top: 56px;

        @media (--viewport-tablet) {
            @mixin colls 8;
            margin-top: 0;
        }
        @media (--viewport-desktop) {
            @mixin colls 9;
        }

        .all-jobs-count {
            display: none;
            @media (--viewport-tablet) {
                display: block;
            }
        }
    }

    .careers-jobs {
        display: grid;
        column-gap: 16px;
        grid-template-columns: 1fr;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        width: 100%;

        @media (--viewport-tablet) {
            grid-template-columns: 1fr 1fr;
        }
        @media (--viewport-desktop) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media (--viewport-desktop-wide) {
            column-gap: 16px;
        }
    }

    .careers-job {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0;
        height: 96px;

        > .title {
            text-decoration: none;
            overflow: hidden;

            .a-link__content {
                @mixin lead-accent;
            }
        }
        .locations {
            @mixin body;
            margin-top: auto;
            color: var(--av-fixed-primary);
        }
    }

    .careers-line {
        width: 100%;
        display: block;
        height: 1px;
        background: var(--av-fixed-lightest);
        margin: 32px 0;

        &:last-of-type {
            display: none;
        }
        @media (--viewport-tablet) {
            grid-column: 1 / 3;
        }
        @media (--viewport-desktop) {
            grid-column: 1 / 4;
        }
    }

    .careers-more {
        @mixin paragraph-accent;
        width: 100%;
        margin-top: 48px;
        height: 42px;
        justify-content: center;
        color: var(--av-brand-primary);
        box-shadow: inset 0 0 0 1px var(--av-brand-secondary-light);

        .a-glyph {
            fill: var(--av-brand-primary);
            margin-inline-start: 8px;
        }
        &.active {
            .a-glyph {
                transform: scaleY(-1);
            }
        }
    }

    .el-input__editor {
        @mixin paragraph;
    }
}

.company-pages+.s-global-footer--locale-selector-visible {
    z-index: 2004;
}

.no-job-for-slug {
    color: var(--av-fixed-secondary);
    background: var(--av-fixed-danger-accent);
    border-radius: 4px;
    padding: 16px 24px;
    padding-inline-start: 48px;
    display: inline-block;
    overflow: hidden;
    word-break: break-word;

    .a-glyph {
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin-inline-start: -28px;
        margin-inline-end: 8px;
        fill: var(--av-fixed-danger)
    }

    > span {
        @mixin paragraph;
        vertical-align: middle;
        font-weight: 600;
    }
}
</style>
