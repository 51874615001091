<template>
    <section :id="id" class="s-light-wrapper-slice" :class="basicClassName">
        <component
            :is="slice.name"
            v-for="(slice, index) in slices"
            v-bind="slice.data"
            :key="index"
        />
    </section>
</template>

<script>
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SLightWrapperSlice',

    mixins: [styleMixin],

    props: {
        slices: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
