<template>
    <div v-if="content && isFormFieldsReady" ref="wrapper" class="wrapper">
        <div class="s-form-registration" :class="{ ...className }">
            <div v-if="isLoading" class="s-form-registration__loading">
                <div v-loading="true" el-loading-size="48" :el-loading-text="content.loading || ''" />
            </div>
            <div v-if="content.title" class="s-form-registration__title" v-html="content.title" />

            <div v-if="content.tooltip?.title" class="form-tooltip">
                {{ content.tooltip.title }}
                <a-tooltip v-if="content.tooltip.text" :text="content.tooltip.text" />
            </div>

            <div v-if="content.subtitles" class="s-form-registration__subtitle">
                <p
                    v-for="(item, i) in content.subtitles"
                    :key="`subtitle-${i}`"
                    class="s-form-registration__subtitle-item"
                    v-html="item"
                />
            </div>
            <ul v-if="content.checklist" class="s-form-registration__checklist">
                <li
                    v-for="(item, i) in content.checklist"
                    :key="`check-${i}`"
                    class="s-form-registration__checklist-item"
                >
                    <a-glyph name="check-square" />
                    <span v-html="item" />
                </li>
            </ul>

            <div v-if="content.link" class="s-form-registration__link" v-html="content.link" />

            <div v-if="content.info" class="info">
                <p v-if="content.info.title" class="info-list-title" v-html="content.info.title" />
                <a-label v-if="content.info.label" v-bind="content.info.label">
                    {{ content.info.label.text }}
                </a-label>
                <ul v-if="isVisibleInfoList" class="info-list">
                    <li
                        v-for="(item, itemIndex) in content.info.list"
                        :key="`info-${itemIndex}`"
                        :class="['info-list-item', item.className]"
                    >
                        <a-link v-if="item.link" v-bind="item.link" />
                        <template v-else>
                            <a-glyph v-if="item.glyph" v-bind="item.glyph" />
                            {{ item.text }}
                        </template>
                    </li>
                </ul>
                <p v-if="content.info.lead" class="info-lead" v-html="content.info.lead" />
                <p v-if="content.info.description" class="info-description" v-html="content.info.description" />
            </div>

            <el-form ref="form" :rules="rules" :model="form">
                <template v-for="(elRowItem, count) in listFieldSpliced">
                    <a-row :key="`a-row-${count}`" :gutter="16">
                        <template v-for="(item, i) in elRowItem">
                            <div v-if="item.section" :key="item.section" class="s-form-registration__section">
                                {{ item.section }}
                            </div>
                            <a-col
                                v-if="isVisibleField(item, ['select', 'select-searchable'])"
                                :key="`select-${i}`"
                                :sm="getInputSize(item, 'sm')"
                                :lg="getInputSize(item, 'lg')"
                            >
                                <el-form-item :prop="item.name" :hint="item.hint">
                                    <el-select
                                        v-bind="labelSelect"
                                        :ref="`${item.name}`"
                                        v-model="form[item.name]"
                                        :filterable="item.type === 'select-searchable'"
                                        :hide-on-resize="isDesktop"
                                        :label="formLabelText(item)"
                                        :multiple-show-items-limit="item.multipleLimit || 1"
                                        :multiple="item.isMultiple"
                                        :name="item.name"
                                        :required="isRequiredSelect(item)"
                                        :popper-class="`el-select-dropdown__form-registration ${item.dropdownClass || ''}`"
                                        :placeholder="item.placeholder || labelSelect.placeholder"
                                        :popper-placement="item['popper-placement']"
                                        :popper-max-width="item['popper-max-width']"
                                        @change="onChangeSelect(item)"
                                    >
                                        <template v-if="item?.options?.[0]?.group">
                                            <el-option-group
                                                v-for="(option, optionIndex) in item.options"
                                                :key="`option-group-${optionIndex}`"
                                                :label="option.label"
                                            >
                                                <el-option
                                                    v-for="(group, groupIndex) in option.group"
                                                    :key="`option-group-item-${groupIndex}`"
                                                    :label="group.label"
                                                    :sub-label="group['sub-label']"
                                                    :value="group.value"
                                                />
                                            </el-option-group>
                                        </template>
                                        <template v-else>
                                            <el-option
                                                v-for="(option, optionIndex) in listOptions(item)"
                                                :key="`option-item-${optionIndex}`"
                                                :label="option.label || option.name"
                                                :sub-label="option['sub-label']"
                                                :value="option.iso_code || option.value || option.code"
                                            />
                                        </template>
                                    </el-select>
                                </el-form-item>
                            </a-col>
                            <a-col
                                v-if="isVisibleField(item, ['input', 'textarea'])"
                                :key="`input-${i}`"
                                :sm="getInputSize(item, 'sm')"
                                :lg="getInputSize(item, 'lg')"
                            >
                                <template v-if="item.name === 'phone' && usePhoneInput">
                                    <a-phone-input
                                        :prop="item.name"
                                        :settings="phoneInputSettings"
                                        :default-phone="form.phone"
                                        :default-country="form.phoneCountryCode || form.country"
                                        :required="isRequired(item)"
                                        @change="onPhoneInputChange"
                                    />
                                </template>
                                <template v-else>
                                    <el-form-item :prop="item.name" :hint="inputHint(item)" :class="{ ...classInputHint(item) }">
                                        <el-input
                                            :ref="`${item.name}`"
                                            v-model="form[item.name]"
                                            :autosize="item.autosize"
                                            :disabled="isLoading"
                                            :label="formLabelText(item)"
                                            :maxlength="item.maxlength"
                                            :name="item.name"
                                            :placeholder="item.placeholder"
                                            :prefix-value="setPrefixValue(item)"
                                            :required="isRequired(item)"
                                            :type="item.type || 'input'"
                                            @blur="checkEmailOptIn(item.name)"
                                            @mouseleave.native="checkEmailOptIn(item.name)"
                                            @click="$emit('clearErrorMessage')"
                                        />
                                        <div v-if="item.tooltip" class="el-form-item__tooltip" v-html="item.tooltip" />
                                        <div
                                            v-if="item.name === 'email' && errorMessageAPI"
                                            :class="classErrorMessage(item)"
                                            v-html="errorMessageAPI"
                                        />
                                    </el-form-item>
                                </template>
                            </a-col>
                            <a-col
                                v-if="isVisibleField(item, 'password')"
                                :key="`password-${i}`"
                                :sm="getInputSize(item, 'sm')"
                                :lg="getInputSize(item, 'lg')"
                            >
                                <el-form-item :prop="item.name" :hint="item.hint">
                                    <el-password
                                        :ref="`${item.name}`"
                                        v-model="form[item.name]"
                                        with-icon
                                        :name="item.name"
                                        :required="isRequired(item)"
                                        :label="formLabelText(item)"
                                    />
                                    <div v-if="item.tooltip" class="el-form-item__tooltip">
                                        {{ item.tooltip }}
                                    </div>
                                </el-form-item>
                            </a-col>
                            <a-col v-if="isVisibleField(item, 'radio') && item.list" :key="`radio-${i}`">
                                <el-form-error v-if="isSubmitted && item.required && !userData[item.name]" class="el-radio-error-message">
                                    {{ item.error }}
                                </el-form-error>
                                <el-radio
                                    v-for="(radio, radioCount) in item.list"
                                    :key="`radio-item-${radioCount}`"
                                    v-model="form[item.name]"
                                    :label="radio.value"
                                    @change="isSubmitted = false"
                                >
                                    {{ radio.title }}
                                    <template #description>
                                        {{ radio.description }}
                                    </template>
                                </el-radio>
                            </a-col>
                        </template>
                    </a-row>
                </template>
                <a-row v-if="hasCheckboxesWEB46049" class="s-form-checkboxes">
                    <p class="s-form-checkboxes-description">
                        {{ content.checkboxes.description }}
                    </p>
                    <div class="s-form-checkboxes-wrapper">
                        <div
                            v-for="(group, groupIndex) in content.checkboxes.groups"
                            :key="`s-form-checkboxes-group-${groupIndex}`"
                            class="s-form-checkboxes-group"
                        >
                            <p class="s-form-checkboxes-title">
                                {{ group.title }}
                            </p>
                            <div class="s-form-checkboxes-list">
                                <el-checkbox
                                    v-for="(item, itemIndex) in group.list"
                                    :key="`s-form-checkboxe-${itemIndex}`"
                                    ref="checkboxesWEB46049"
                                    v-model="group.list[item]"
                                    :name="item.value"
                                >
                                    {{ item.text }}
                                </el-checkbox>
                            </div>
                        </div>
                    </div>
                </a-row>
                <div v-if="content.links" class="s-form-registration__links">
                    <div v-for="(item, i) in content.links" :key="`link-${i}`" class="s-form-registration__links-item">
                        <a-link class="s-form-registration__footer-item" v-bind="item" />
                    </div>
                </div>
                <div v-for="(item, i) in content.notifications" :key="`notification-${i}`">
                    <div v-if="isVisibleNotification(item)" class="s-form-notification-item">
                        <a-glyph v-if="item.glyph" :name="item.glyph" />
                        <span v-html="item.text" />
                    </div>
                </div>
                <div v-if="hasCheckboxes" class="s-form-registration__checkboxes">
                    <template v-for="item in listCheckboxes">
                        <el-checkbox
                            v-if="isVisibleCheckbox(item)"
                            :key="item.name"
                            v-model="form[item.name]"
                            :name="item.name"
                            :multiline-label="item.multilineLabel"
                            :multiline-label-limit="item.multilineLabelLimit"
                            @change="isSubmitted = false"
                        >
                            <a-dangerous-html :content="formCheckboxText(item)" />
                            <el-form-error v-if="isVisibleErrorCheckbox(item)" class="checkbox-error-message">
                                <span v-html="item.error || formElementsDefault?.errors?.[item.name] || 'Error'" />
                            </el-form-error>
                        </el-checkbox>
                    </template>
                </div>
                <div v-if="hasAgreement" class="agreement">
                    <a-dangerous-html
                        v-for="(item, i) in listCheckboxesLightGDPR"
                        :key="`light-gdpr-notification-${i}`"
                        :content="formCheckboxText(item)"
                    />
                </div>

                <a-form-honeypot v-if="content.hasHoneypot" ref="honeypot" />

                <a-button
                    v-if="content.button"
                    class="s-form-registration__button"
                    v-bind="content.button"
                    :event="{ doNotSendGA: true }"
                    :size="content.button?.size || 'm'"
                    :disabled="isButtonDisable"
                    @click="submit"
                />
                <div v-if="content.notes" class="s-form-registration__note">
                    <p
                        v-for="(item, i) in content.notes"
                        :key="i"
                        class="s-form-registration__note-item"
                        :class="item.class || null"
                        v-html="item.title || item"
                    />
                </div>
            </el-form>
            <template v-if="content.extraButtons?.length">
                <a-button
                    v-for="(button, i) in content.extraButtons"
                    :key="`form-reg-extra-button-${i}`"
                    :event="button.event || { doNotSendGA: true }"
                    class="s-form-registration__extra-button"
                    v-bind="button"
                />
            </template>
            <p v-if="content.changeAccount" class="change">
                <a-link :text="content.changeAccount" @click="changeAccount()" />
            </p>
            <template v-if="hasRecaptchaInvisible">
                <div class="recaptcha-notification" v-html="formRecaptchaText" />
            </template>
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive.js';
import { get, size, filter, isEmpty, fromPairs, isString } from 'lodash';
import { mapState, mapActions } from 'vuex';
import AButton from '@core/components/button/button.vue';
import ACol from '@core/components/col/col.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AFormHoneypot from '@core/components/form-honeypot/form-honeypot.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import APhoneInput from '@core/components/phone-input/phone-input.vue';
import ARow from '@core/components/row/row.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import phoneInput from '@core/mixins/phone-input.js';
import recaptcha from '@core/mixins/recaptcha.js';
import states from '@model/const/states.ts';
import utils from '@utils/form.js';

export default {
    name: 'FormRegistration',

    components: {
        AButton,
        ADangerousHtml,
        AFormHoneypot,
        APhoneInput,
        AGlyph,
        ALabel,
        ALink,
        ARow,
        ACol,
        ElCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
        ElForm: () => import('@uikit/ui-kit/packages/form'),
        ElFormError: () => import('@uikit/ui-kit/packages/form-error'),
        ElFormItem: () => import('@uikit/ui-kit/packages/form-item'),
        ElInput: () => import('@uikit/ui-kit/packages/input'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        ElOptionGroup: () => import('@uikit/ui-kit/packages/option-group'),
        ElPassword: () => import('@uikit/ui-kit/packages/password'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElRadio: () => import('@uikit/ui-kit/packages/radio'),
    },

    directives: {
        Loading,
    },

    mixins: [form, utils, recaptcha, phoneInput, breakpoint],

    props: {
        usePhoneInput: {
            type: Boolean,
            default: false,
        },
        content: {
            type: Object,
            default: null,
        },
        userData: {
            type: Object,
            default: null,
        },

        settingsForm: {
            type: Object,
            default: null,
        },

        mailgunError: {
            type: Object,
            default: null,
        },

        errorMessageAPI: {
            type: String,
            default: '',
        },
    },

    emits: ['submit', 'submit-error', 'resize', 'change-step', 'clearErrorMessage'],

    data: () => ({
        hash: '',
        wrapperHeight: 0,
        form: {},
        isFormFieldsReady: false,
        resellerList: [],
        distributorList: [],
        isButtonDisable: false,
        isSubmitted: false,
    }),

    computed: {
        ...mapState({
            countries: (state) => state.countries?.items || [],
            points: (state) => get(state, 'slices.items.s-data-centers.points', []),
            queryStringData: (state) => state.trial?.queryStringData || {},
        }),

        dataCenters() {
            return utils.getUniqueDataCentersForPlatform(this.points).map((item) => ({
                ...item,
                name: item.country,
                code: item.marketoCode,
            }));
        },

        rules() {
            const result = {};
            this.listField.forEach((item) => {
                result[item.name] = {
                    fieldType: item.type,
                    required: this.isItemRequired(item),
                    validator: this.validateFieldValue,
                    errorMessage: this.formErrorText(item),
                    trigger: item.trigger || 'change',
                };

                if (item.name === 'email') {
                    result[item.name] = {
                        isBusinessOnlyEmail: item.isBusinessOnlyEmail,
                        isBusinessEmailByCountry: item.isBusinessEmailByCountry,
                        errorMessageBusiness: item.errorBusinessEmail || this.setErrorMessage('invalidEmailBusiness'),
                        errorMessage: item.error || this.setErrorMessage('notFilledField'),
                        errorMessageMailgun: this.mailgunError,
                        validator: this.validateEmail.bind(this),
                        trigger: item.trigger || 'change',
                    };
                }

                if (!this.usePhoneInput && item.name === 'phone') {
                    result[item.name] = {
                        validator: this.validatePhone,
                        errorMessage: this.formErrorText(item),
                        trigger: item.trigger || 'change',
                    };
                }

                if (item.name === 'password') {
                    result[item.name] = {
                        validator: this.validatePassword,
                        errorMessage: this.formErrorText(item),
                        trigger: item.trigger || 'change',
                    };
                }

                if (this.fitsWeb46734(item)) {
                    result[item.name] = {
                        validator: this.validateNameFieldExtended,
                        errorMessage: this.formErrorText(item),
                    };
                }
            });
            return result;
        },

        className() {
            return {
                's-form-registration-sem-ebook': this.content.isEbook,
                [`s-form-registration-version-${this.content.version}`]: this.content.version,
            };
        },

        listField() {
            return this.content?.fields || [];
        },

        listFieldSpliced() {
            const result = [];
            const list = [...this.listField];
            while (list.length > 0) {
                const count = list[0].name === 'country' ? 3 : 2;
                result.push(list.splice(0, count));
            }
            return result;
        },

        listAgreements() {
            return get(this, 'content.agreements', []);
        },

        listCheckboxes() {
            return filter(this.listAgreements, ['type', 'checkbox']);
        },

        listCheckboxesGDPR() {
            return filter(this.listAgreements, ['isGDPR', true]);
        },

        listCheckboxesLightGDPR() {
            return filter(this.listAgreements, ['isLightGDPR', true]);
        },

        hasAgreement() {
            const comparison = (item) => item.name === 'country';
            return this.listField.find(comparison) && this.form.country && !this.isCountryGDPR;
        },

        hasCheckboxes() {
            const listGDPR = this.listCheckboxesGDPR;
            const list = this.listCheckboxes.filter((item) => !item.isGDPR);
            return size(list) || (size(listGDPR) && this.isCountryGDPR);
        },

        hasCheckboxesWEB46049() {
            return this.content?.checkboxes?.groups?.length;
        },
        isVisibleInfoList() {
            if (!this.isDesktop && this.content.info.list.length > 3) return false;
            return this.content.info.list;
        },
        formEventContext() {
            if (!this.hasCheckboxesWEB46049) return '';
            return this.$refs.checkboxesWEB46049
                .filter((item) => item.isChecked)
                .map((item) => item.name)
                .join(',');
        },
    },

    watch: {
        $route(to) {
            const hash = to.hash.replace('#', '');
            if (this.hash === hash) return;
            this.hash = hash;
        },
        mailgunError: {
            async handler(value) {
                if (!value) return;
                if (!this.$refs.form) return;
                await this.$nextTick();
                this.$refs.form.validateField('email');
            },
            immediate: true,
        },
        'form.country': {
            handler(value) {
                if (!value) return;
                this.getResellerList();
                this.getDistributorList();
            },
        },
    },

    updated() {
        const height = this.$refs.wrapper?.clientHeight || 0;
        if (this.wrapperHeight !== height) {
            this.wrapperHeight = height;
            this.$emit('resize');
        }

        if (this.hash && this.$refs.form) {
            if (this.hash === this.content.sliceID) {
                this.setFocus();
            }
            this.hash = '';
            this.$nextTick(() => {
                this.$refs.form.clearValidate();
            });
        }
    },

    beforeDestroy() {
        window.removeEventListener('keydown', this.onKeyDown);
    },

    mounted() {
        document.addEventListener('keydown', this.onKeyDown);

        this.initFields();

        if (isEmpty(this.userData)) {
            this.init();
            return;
        }

        this.prefillForm();
        this.setFocus();
    },

    methods: {
        ...mapActions({
            getCompanyList: 'partnersLocator/getCompanyList',
        }),

        fitsWeb46734(item) {
            if (!this.$route.path.includes('/products/cyber-protect/trial/')) return false;
            if (['/en-us/', '/en-eu/', '/en-sg/', '/en-gb/'].includes(this.locale)) return false;
            return ['first_name', 'last_name'].includes(item.name);
        },

        hasHintForFreeEmail(item) {
            return item.hintForFreeEmail && this.isFreeEmail(this.form.email);
        },

        inputHint(item) {
            if (this.hasHintForFreeEmail(item)) return item.hintForFreeEmail;
            return item.hint || item.description || '';
        },

        classInputHint(item) {
            return { 'el-form-item-hint-critical': this.hasHintForFreeEmail(item) };
        },

        classErrorMessage(item) {
            return {
                'el-form-error-api': item,
                [`el-form-error-api-${item.name}`]: item.name,
            };
        },

        async changeAccount() {
            this.$refs.form.clearValidate();
            await this.$store.dispatch('user/logout');
        },

        async getResellerList() {
            const comparison = (f) => f.name === 'reseller' && f.getReseller;
            const field = this.listField.find(comparison);
            if (!field) return;
            try {
                this.isLoading = true;
                this.form.reseller = '';
                this.form.reseller_id = '';
                const country = this.form.country;
                const response = await this.getCompanyList({ country, type: 'partners' });
                const list = response.data || [];
                const type = field.typeReseller;
                this.resellerList = list
                    .filter((item) => (type ? item[type] : item))
                    .map((item) => ({ code: item.id, name: item.name }));
            } catch (e) {
                this.resellerList = [];
            } finally {
                this.isLoading = false;
            }
        },

        async getDistributorList() {
            const comparison = (f) => f.name === 'distributor' && f.getDistributor;
            const field = this.listField.find(comparison);
            if (!field) return;
            try {
                this.isLoading = true;
                this.form.distributor = '';
                this.form.distributor_id = '';
                const country = this.form.country;
                const response = await this.getCompanyList({ country, type: 'distributors' });
                const list = response.data || [];
                const type = field.typeDistributor;
                this.distributorList = list
                    .filter((item) => (type ? item[type] : item))
                    .map((item) => ({ code: item.id, name: item.name }));
            } catch (e) {
                this.distributorList = [];
            } finally {
                this.isLoading = false;
            }
        },

        initFields() {
            this.form = {
                ...fromPairs(this.listField.map((el) => [el.name, ''])),
                ...fromPairs(this.listCheckboxes.map((el) => [el.name, el.isChecked || false])),
            };

            if (this.content.isFormExpressSignup) {
                this.setSearchParamsData();
            }

            this.isFormFieldsReady = true;
        },

        prefillData(key) {
            if (!Object.keys(this.queryStringData)?.length && !localStorage.getItem(`trial_es_${key}`)) return;
            const query = this.queryStringData;
            const unexpectedKeys = {
                first_name: 'firstname',
                last_name: 'lastname',
                tenantId: 'uid',
            };

            const val = query[key] || query[unexpectedKeys[key]] || localStorage.getItem(`trial_es_${key}`);
            if (!val || val === 'undefined') return;

            const normalizedValue = key === 'email' && isString(val) ? val?.replaceAll(' ', '+') : val;
            this.form[key] = normalizedValue;
            localStorage.setItem(`trial_es_${key}`, normalizedValue);
        },

        setSearchParamsData() {
            this.searchParams = new URLSearchParams(this.queryStringData);
            const expectedKeys = new Set([
                'aan',
                'company',
                'email',
                'first_name',
                'last_name',
                'phone',
                'currency',
                'price',
                'opportunity_id',
                'tenantId',
            ]);
            Object.keys(this.form)
                .filter((x) => expectedKeys.has(x))
                .forEach(this.prefillData);
            if (Object.keys(this.$route?.query || {}).length) this.$router.replace({ query: null });
        },

        isValidGDPR(item) {
            return !item.isGDPR || this.isCountryGDPR;
        },

        hasCheckboxRequired(item) {
            return item.isRequired && !this.form[item.name] && this.isValidGDPR(item);
        },

        init() {
            this.setCountry();
            this.setState();
            this.setDataCenterLocation();
            this.setAccountData();

            if (this.content.focusNotSet) return;
            this.setFocus();
        },

        async submit() {
            this.isSubmitted = true;

            if (this.hasCheckboxes) {
                const required = this.listCheckboxes.find((item) => this.hasCheckboxRequired(item)) || {};
                if (required.error || this.formElementsDefault.errors?.[required.name]) return;
            }

            try {
                this.isButtonDisable = true;

                this.prepareFormToValidate();
                await this.$refs.form.validate();

                const payload = this.form;
                const initialPayloadToLog = { ...this.form };

                const distributor = this.distributorList.find((item) => item.code === this.form.distributor);
                if (distributor) {
                    payload.distributor = distributor.name;
                    payload.distributor_id = distributor.code;
                }

                const reseller = this.resellerList.find((item) => item.code === this.form.reseller);
                if (reseller) {
                    payload.reseller = reseller.name;
                    payload.reseller_id = reseller.code;
                }

                // WEB-42323
                if (this.content.hasHoneypot) {
                    payload.business_address = this.$refs.honeypot.getValue() || false;
                    if (this.$refs.honeypot.isCaptured()) {
                        this.$refs.honeypot.sendGAEvent();
                    }
                }

                // WEB-43432
                if (this.content.isFormEvents) {
                    let sendNotSpEvent;
                    try {
                        sendNotSpEvent = await this.checkIfSuitableForNotSpEvent();
                    } catch (error) {
                        sendNotSpEvent = false;
                    }
                    if (sendNotSpEvent) this.sendNotSpEvent();

                    payload.SP_validation = null;
                    const term = this.$route.query?.utm_term;
                    if (['form', 'ccform'].includes(term) || window.sessionStorage?.getItem('utm_term')) {
                        payload.SP_validation = !sendNotSpEvent;
                    }

                    if (!payload.meeting_requested) {
                        payload.meeting_requested = null;
                    }
                }

                console.log('on submit', { initialPayloadToLog, payload }); // eslint-disable-line

                this.$emit('submit', payload, this.$refs?.form?.$el, this.formEventContext);
            } catch (e) {
                this.setFocusErrorField();
            } finally {
                this.isButtonDisable = false;
            }
        },

        setListPartners(item) {
            if (item.name === 'reseller' && !item.getReseller) return item.options;
            if (item.name === 'distributor' && !item.getDistributor) return item.options;
            const options = item.name === 'reseller' ? this.resellerList : this.distributorList;
            return [item.options[0], ...options || [], item.options[1]].filter(Boolean);
        },

        listOptions(item) {
            const list = {
                state: states,
                country: this.countries,
                dc_location: this.dataCenters,
            };

            const field = this.listField.find((el) => el.name === item.name);
            let options = list[item.name] || field.options || this.formListOption(item.name);

            if (field.affix) {
                options = options.map((el) => ({
                    ...el,
                    code: `${el.code} ${field.affix}`,
                }));
            }

            if (['distributor', 'reseller'].includes(item.name)) return this.setListPartners(item);

            const selectedOption = options.find((el) => el.selected);
            if (selectedOption && !this.form[item.name]) this.form[item.name] = selectedOption.code;

            return options;
        },

        setPrefixValue(item) {
            if (item.placeholder) return '';
            return item.name === 'phone' ? '+' : '';
        },

        setCountry() {
            const isoCode = this.location?.data?.country?.code;
            const hasCountry = this.countries.find((item) => item.iso_code === isoCode);
            this.form.country = hasCountry ? isoCode : 'US';
        },

        setState() {
            if (!('state' in this.form)) return;
            if (this.form.country !== 'US') return;
            if (this.location?.data?.country?.code !== 'US') {
                this.form.state = states[0]?.code || '';
                return;
            }
            this.form.state = this.location?.data?.region?.code || states[0]?.code || '';
        },

        setDataCenterLocation() {
            const field = this.listField.find((item) => item.name === 'dc_location');
            if (!field) return;

            const code = utils.setDataCenter(this.dataCenters, this.form.country);
            if (!code) return;

            this.form.dc_location = field.affix ? `${code} ${field.affix}` : code;
        },

        prefillForm() {
            this.form = {
                ...this.userData,
                ...fromPairs(this.listCheckboxes.map((el) => [el.name, false])),
            };
        },

        isVisibleNotification(item) {
            if (item.visible) {
                if (!item.visible.list || !item.visible.field) {
                    return false;
                }
                return item.visible.list.includes(this.form[item.visible.field]);
            }
            return false;
        },

        isVisibleCheckbox(item) {
            return item.isOptIn ? this.isVisibleOptIn : this.isValidGDPR(item);
        },

        isVisibleErrorCheckbox(item) {
            return this.isSubmitted && item.isRequired && !this.form[item.name];
        },

        getInputSize(item, sizeID) {
            if (item.sizes && item.sizes[sizeID]) {
                return item.sizes[sizeID];
            }

            if ((item.isDynamic && this.showStatesField) || this.showPostalCodeField) {
                return 12;
            }
            return item.size || 12;
        },

        isMailProp(name) {
            return name === 'email';
        },

        isItemRequired(item) {
            if (item.name === 'message' && this.content.is46514VariantB) {
                return this.form.company_type === 'Corporate End-Customer';
            }
            return item.required;
        },

        onChangeSelect(item) {
            this.setState();
            this.checkEmailOptIn(item.name);
        },

        onKeyDown(event) {
            if (event.key === 'Enter') {
                const fields = this.content?.fields || [];
                const field = fields.find((item) => item.name === event.target.name);
                if (field?.type.includes('select')) {
                    event.target.click();
                }
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-form-registration {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 40px 16px 56px;
    justify-content: center;
    background: var(--av-inversed-primary);

    @media (--viewport-mobile-wide) {
        border-radius: 4px;
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-brand-light);
    }

    @media (--viewport-tablet) {
        padding: 32px;
    }

    &__loading {
        top: 0;
        inset-inline-start: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        border-radius: 4px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: var(--av-inversed-secondary);

        &:deep(.el-loading) {
            .el-text {
                margin-top: 20px;
            }
        }
    }

    &__button {
        width: 100%;
    }

    &__title {
        @mixin title-accent;
        margin: 0 -2px;
        text-align: center;
        color: var(--av-nav-primary);
    }

    .form-tooltip {
        @mixin paragraph;
        display: flex;
        margin: 8px 0 0;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: var(--av-fixed-primary);

        .a-tooltip {
            top: 2px;
            position: relative;
            background: transparent;
            margin-inline-start: 8px;
        }
    }

    &__subtitle {
        @mixin lead;
        margin: 8px 0 0;
        text-align: center;

        &:deep(a) {
            cursor: pointer;
            text-decoration: none;
            color: var(--av-brand-secondary);

            &:hover {
                color: var(--av-brand-secondary);
            }
        }
    }

    &__checklist {
        margin-top: 16px;
    }

    &__checklist-item {
        @mixin body;
        display: flex;
        align-items: center;

        .a-glyph {
            margin-inline-end: 8px;
            position: relative;
            top: 1px;
            fill: var(--av-fixed-success);
        }
    }

    &__link {
        @mixin paragraph;
        margin: 8px 0 0;
        text-align: center;
        color: var(--av-fixed-secondary);

        &:deep(a) {
            cursor: pointer;
            text-decoration: none;
            color: var(--av-brand-secondary);

            &:hover {
                color: var(--av-brand-secondary);
            }
        }
    }

    .info {
        text-align: center;
        margin-top: 24px;
        @media (--viewport-tablet) {
            margin-top: 16px;
        }
        @media (--viewport-desktop) {
            margin-top: 24px;
        }
        .a-label {
            display: flex;
            margin: 0 auto;
            width: max-content;
        }
        .info-list-title {
            margin-bottom: 24px;
            color: var(--av-nav-primary);
        }
        .info-list {
            gap: 24px;
            display: flex;
            overflow: hidden;
            margin-top: 8px;
            justify-content: space-between;
        }
        .info-list-item {
            @mixin body;
            gap: 4px;
            flex: 1 1 0;
            display: flex;
            align-items: center;
            white-space: nowrap;
            color: var(--av-nav-primary);
            .a-glyph {
                fill: var(--av-nav-primary);
            }
            &:last-child {
                justify-content: flex-end;
            }
            &.active {
                color: var(--av-brand-primary);
                .a-glyph {
                    fill: var(--av-brand-primary);
                }
            }
            &.has-background {
                &:before {
                    content: '';
                    height: 2px;
                    width: 100%;
                    border-radius: 2px;
                    background: linear-gradient(90deg, #CDE2FF 0%, #7DB7FF 100%);
                }

            }
        }
        .info-lead {
            @mixin lead-accent;
            margin-top: 24px;
            color: var(--av-nav-primary);
        }
        .info-description {
            @mixin paragraph;
            margin-top: 8px;
            color: var(--av-fixed-secondary);
            @media (--viewport-desktop-wide) {
                padding: 0 60px;
            }
        }
    }

    .s-form-checkboxes {
        border-radius: 4px;
        margin-bottom: 16px;
        padding: 8px 16px 24px;
        color: var(--av-fixed-primary);
        background: var(--av-fixed-info-accent);

        .s-form-checkboxes-description {
            @mixin paragraph-accent;
            text-align: center;
        }

        .s-form-checkboxes-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px;

            .s-form-checkboxes-group {
                width: 50%;
                padding: 0 8px;
                margin-top: 8px;

                &:last-child {
                    width: 100%;

                    .s-form-checkboxes-list {
                        display: flex;

                        .el-checkbox {
                            width: 50%;
                        }
                    }
                }

                &:deep(.el-checkbox) {
                    @mixin caption;
                    padding: 4px 0;

                    &:not(:first-child) {
                        margin: 0;
                    }

                    .el-checkbox__content {
                        align-items: flex-start;
                    }
                }
            }

            .s-form-checkboxes-title {
                @mixin caption-accent;
            }

            .s-form-checkboxes-list {
                margin-top: 8px;
            }
        }
    }

    &__section {
        @mixin paragraph-accent;
        padding: 0 8px 8px;
        text-align: center;

        @media (--viewport-tablet) {
            text-align: start;

            &:not(:first-child) {
                padding: 24px 8px 8px;
            }
        }

        &:not(:first-child) {
            padding: 16px 8px 8px;
        }
    }

    .s-form-notification-item {
        @mixin descriptor;
        display: flex;
        padding: 16px;
        position: relative;
        padding-inline-start: 48px;
        padding-inline-end: 24px;
        margin: 0 0 16px;
        background-color: var(--av-solid-brand-secondary-accent);

        &:not(:first-child) {
            margin: 8px 0 16px;
        }

        &:deep(a) {
            text-decoration: none;
            color: var(--av-brand-secondary);
        }

        &:deep(.a-glyph) {
            min-width: 16px;
            margin-top: 4px;
            margin-inline-start: -24px;
            margin-inline-end: 8px;
        }
    }

    &:deep(.el-form) {
        margin: 32px 0 0;

        @media (--viewport-tablet) {
            margin-top: 24px;
        }

        .el-input__label {
            &.is-active {
                font-weight: 700;
            }
        }

        .el-input__container {
            .el-input__wrapper {
                order: 1;
            }

            .el-input__suffix {
                order: 3;
            }

            .el-input__required {
                order: 2;
                padding: 0 8px;
            }
        }

        .el-radio-error-message {
            @mixin body;
            justify-content: center;
            color: var(--av-fixed-danger);
        }

        .el-radio {
            width: 100%;
            border-radius: 4px;
            padding: 16px 24px;
            border: 1px solid var(--av-brand-primary);
            &:not(:first-child) {
                margin-top: 16px;
            }
            &:last-child {
                margin-bottom: 16px;
            }
            .el-radio__label {
                @mixin lead-accent;
                color: var(--av-fixed-primary);
            }
            .el-radio__description {
                @mixin paragraph;
                margin-top: 4px;
                color: var(--av-fixed-primary);
            }
        }

        .el-form-item__tooltip {
            /* inset-inline-start/end is not needed for this tooltip */
            @mixin caption-accent;
            z-index: 1;
            width: 100%;
            bottom: 100%;
            display: none;
            padding: 16px;
            margin-bottom: 8px;
            border-radius: 4px;
            position: absolute;
            inset-inline-start: 50%;
            transform: translateX(-50%);
            color: var(--av-inversed-primary);
            background: var(--av-fixed-primary);
            box-shadow: var(--av-shadow-regular);

            &::after {
                width: 0;
                height: 0;
                inset-inline-start: 50%;
                transform: translateX(-50%);
                top: 100%;
                content: '';
                display: block;
                position: absolute;
                border-style: solid;
                border-width: 4px 4px 0;
                border-color: var(--av-fixed-primary) rgba(255, 255, 255, 0)
                    rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
            }
        }

        .el-form-item__error {
            @mixin caption-accent;
            padding-bottom: 16px;
        }

        .el-form-item__hint {
            @mixin caption-accent;
            margin-bottom: 16px;
            color: var(--av-fixed-light);
        }

        .el-form-item-hint-critical {
            .el-form-item__hint {
                color: var(--av-fixed-critical);
            }
        }

        .el-form-item {
            &.is-error {
                .el-input__icon {
                    .svg {
                        fill: var(--av-fixed-danger);
                    }
                }
            }
            &.is-focus {
                .el-input__icon {
                    .svg {
                        fill: var(--av-brand-primary);
                    }
                }
                .el-form-item__tooltip {
                    display: block;
                }
            }
        }

        .el-form-error-api {
            @mixin caption-accent;
            position: relative;
            padding-bottom: 16px;
            color: var(--av-fixed-danger);

            a {
                text-decoration: underline;
            }

            & ~ .el-form-item__error-holder {
                display: none;
            }
        }

        .el-form-error-api-login {
            @media (--viewport-tablet) {
                width: 200%;
            }

            .el-form-item__hint {
                @mixin caption;
                color: var(--av-fixed-light);
            }

            .el-textarea__editor {
                @mixin paragraph;
                color: var(--av-fixed-primary);
                font-family: var(--font-family-default);
            }
        }
    }

    &__error {
        @mixin descriptor;
        display: flex;
        text-align: start;
        align-items: flex-start;
        margin-bottom: 16px;

        &:deep(a) {
            text-decoration: underline;
            color: var(--av-fixed-danger);
        }
    }

    &__checkboxes {
        &:deep(.el-checkbox) {
            &:last-child {
                margin-bottom: 16px;
            }

            .el-checkbox__label {
                @mixin body;
                padding: 0;
                padding-inline-start: 8px;
            }

            .el-checkbox__content {
                align-items: flex-start;
            }

            .el-checkbox__control {
                margin: 4px 0 0;
            }
        }

        .checkbox-error-message {
            @mixin body;
            gap: 8px;
            display: flex;
            align-items: flex-start;
            margin-inline-start: -24px;

            &:deep(.a-glyph) {
                min-width: 16px;
                margin-top: 4px;
                fill: var(--av-fixed-danger);
            }
        }
    }

    .agreement {
        @mixin body;
        text-align: center;
        margin-bottom: 16px;
    }

    .change {
        margin: 16px 0 0;
        text-align: center;
    }

    .recaptcha-notification {
        @mixin body;
        margin-top: 16px;
        text-align: center;
        color: var(--av-fixed-secondary);
    }

    &__note {
        margin: 8px 0 0;

        &-item {
            @mixin caption;
            color: var(--av-fixed-light);
            text-align: center;

            &.large {
                margin: 16px 0 0;
                @mixin body;
                color: var(--av-fixed-secondary);
            }
        }
    }

    &.s-form-registration {
        &-sem-ebook {
            border-radius: 4px;
            padding: 32px 24px 40px;
            border: 1px solid var(--av-fixed-lightest);
            box-shadow: 0 4px 8px var(--av-fixed-lightest);

            .s-form-registration {
                &__title {
                    @mixin lead-accent;
                    font-weight: 500;
                    color: var(--av-nav-primary);
                }
            }
        }
    }

    &__extra-button {
        margin: 16px 0 0;

        &.a-button_type_inverse {
            border-radius: 4px;
            border: 1px solid var(--av-brand-primary);

            &:hover {
                background: var(--av-brand-accent);
            }

            &:active {
                background: var(--el-secondary-active);
            }

            &:deep(.a-button__content) {
                color: var(--av-brand-primary);
                @mixin paragraph-accent;
            }
        }
    }
}

.s-form-registration.s-form-registration-version-42019 {
    overflow: hidden;
    border-radius: 4px;
    padding: 32px 16px 40px;
    border: 1px solid var(--av-brand-light);
    box-shadow: var(--av-shadow-small);

    @media (--viewport-mobile-wide) {
        padding-inline-end: 24px;
        padding-inline-start: 24px;
    }

    @media (--viewport-desktop-wide) {
        padding-inline-end: 48px;
        padding-inline-start: 48px;
    }

    .s-form-registration__subtitle {
        @mixin body;
        margin-top: 16px;
        color: var(--av-fixed-secondary);
    }
}

[dir="rtl"] {
    .s-form-registration {
        &:deep(.el-form) {
            margin: 32px 0 0;
        }
        .el-form-item__tooltip {
            inset-inline-start: auto;
            inset-inline-end: 50%;
        }
    }
}
</style>
