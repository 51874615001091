<template>
    <div class="media-wrapper" :class="wrapperClasses" :tabindex="ctaSettings ? -1 : 0">
        <template v-if="computedType === 'screenshot'">
            <div :class="{ 'no-shadow': !isShadow }" class="media screenshot-wrapper">
                <div v-if="enlargedImage" v-modal="computedModalId" tabindex="0">
                    <div class="enlarged-image screenshot-image">
                        <a-picture
                            v-bind="$props"
                            :class="className"
                            class="full-width"
                            :is-shadow="false"
                        />
                    </div>
                    <div v-if="enlargedImage" class="zoom-in">
                        <a-glyph name="zoom-in" class="icon" />
                    </div>
                </div>
                <template v-if="!disableModal">
                    <a-modal
                        v-if="enlargedImage"
                        :id="computedModalId"
                        :is-overlay-close="isOverlayClose"
                        :caption="caption"
                        :type="isGallery ? 'gallery' : 'screenshot'"
                    >
                        <template #default>
                            <a-picture
                                :link="enlargedImage"
                                position="center"
                                :is-shadow="false"
                                persist-original
                            />
                        </template>
                        <template v-if="isGallery" #gallery>
                            <a-modal-gallery
                                :caption="caption"
                                :next-id="nextId"
                                :prev-id="prevId"
                            />
                        </template>
                    </a-modal>
                    <a-picture
                        v-else
                        :class="className"
                        :is-shadow="false"
                        v-bind="$props"
                        class="full-width screenshot-image"
                    />
                </template>
            </div>
            <div
                v-if="ctaSettings"
                ref="ctaBlock"
                class="cta-block"
                @click="onCtaBlockClick"
            >
                <a-button
                    ref="ctaButton"
                    tabindex="0"
                    class="cta-button"
                    v-bind="ctaSettings"
                    @click="onCtaButtonClick"
                />
            </div>
        </template>
        <template v-else-if="computedType === 'image'">
            <a-picture
                :class="className"
                :is-shadow="false"
                class="media full-width"
                v-bind="$props"
            />
            <div
                v-if="ctaSettings"
                ref="ctaBlock"
                class="cta-block"
                @click="onCtaBlockClick"
            >
                <a-button
                    ref="ctaButton"
                    tabindex="0"
                    class="cta-button"
                    v-bind="ctaSettings"
                    @click="onCtaButtonClick"
                />
            </div>
        </template>
        <a-video-card
            v-else-if="computedType === 'video'"
            v-bind="$props"
            :class="className"
            class="media"
            @progressChanged="$emit('progressChanged', $event)"
        />
        <span v-else>ERR: Unsupported media type!</span>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import AModalGallery from '@core/components/modal/modal-gallery.vue';
import AModal from '@core/components/modal/modal.vue';
import Modal from '@core/directives/modal.js';
import APicture from '../picture/picture.vue';
import AVideoCard from '../video-card/video-card.vue';
import { TYPES } from './constants.js';

export default {
    name: 'AMedia',

    components: {
        AButton,
        AGlyph,
        APicture,
        AModal,
        AVideoCard,
        AModalGallery,
    },

    directives: { Modal },

    props: {
        type: {
            type: String,
            required: true,
            validator: (type) => TYPES.includes(type),
        },

        /* Picture props ((Required ones defined in video-card component) */

        link: {
            type: String,
            default: '',
        },

        alt: {
            type: String,
            default: 'Acronis',
        },

        /**
         * Image's "object-fit" property.
         *
         * If we want always 'contain' but 'cover' only on desktop
         * e.g. { default: 'contain', desktop: 'cover' }
         */
        fit: {
            type: [String, Object],
            default: undefined,
        },

        /**
         * Image's "object-position" property
         */
        position: {
            type: String,
            default: 'center',
        },

        /**
         * Zoom capabilities
         */
        enlargedImage: {
            type: String,
            default: null,
        },

        persistOriginal: {
            type: Boolean,
            default: false,
        },

        /**
         * Disable Modal in case you need it to be external
         */
        disableModal: {
            type: Boolean,
            default: false,
        },
        /**
         * Modal ID for enlarged image.
         */
        modalId: {
            type: String,
            default: null,
        },
        /**
         * Caption to be used in the gallery modal.
         */
        caption: {
            type: String,
            default: null,
        },

        nextId: {
            type: String,
            default: null,
        },

        prevId: {
            type: String,
            default: null,
        },
        /**
         * Control the overlay close property of the modal
         */
        isOverlayClose: {
            type: Boolean,
            default: true,
        },

        /**
         * Picture shadow
         */
        isShadow: {
            type: Boolean,
            default: true,
        },

        /**
         * Is a picture used as a background
         */
        isBackground: {
            type: Boolean,
            default: false,
        },

        /**
         * Image link if picture is used as a background
         */
        background: {
            type: Object,
            default: undefined,
        },

        /**
         * Image size if picture is not a background
         */
        size: {
            type: String,
            default: undefined,
        },

        /* Video Props (Required ones defined in video-card component) */

        /**
         * Youtube id
         */
        ytId: {
            type: String,
            default: undefined,
        },

        /**
         * Video title
         */
        text: {
            type: String,
            default: undefined,
        },

        /**
         * Video time
         */
        time: {
            type: String,
            default: undefined,
        },

        /**
         * Image URL
         */
        image: {
            type: String,
            default: undefined,
        },

        /**
         * Image alt
         */
        imageAlt: {
            type: String,
            default: undefined,
        },

        isEager: {
            type: Boolean,
            default: false,
        },

        /**
         * CTA button settings
         */
        cta: {
            type: Object,
            default: undefined,
        },
    },

    emits: ['click', 'progressChanged'],

    data: () => ({
        isFocus: false,
    }),

    computed: {
        wrapperClasses() {
            return {
                [`media-wrapper--${this.computedType}`]: true,
                'media-with-cta': this.ctaSettings,
                'media-with-enlarge': this.enlargedImage,
            };
        },

        className() {
            const { type } = this;
            return {
                [`a-media__type__${type}`]: type,
            };
        },
        computedType() {
            if (!this.type) return 'image';
            return this.type;
        },
        computedModalId() {
            if (this.modalId) return this.modalId;
            return `${this.link}-${this.enlargedImage}`;
        },
        isGallery() {
            return this.caption || (this.prevId && this.nextId);
        },
        ctaSettings() {
            if (!this.cta || this.enlargedImage) return null;

            const fallback = {
                text: 'Button',
                type: 'main',
                size: 's',
                glyph: 'arrow',
            };
            return { ...fallback, ...this.cta };
        },
    },

    mounted() {
        this.addCtaListeners();
    },

    beforeDestroy() {
        this.removeCtaListeners();
    },

    methods: {
        addCtaListeners() {
            if (!this.ctaSettings || !this.$refs.ctaBlock) return;

            this.$refs.ctaBlock.addEventListener('mouseenter', this.onCtaMouseEnter);
            this.$refs.ctaBlock.addEventListener('mouseleave', this.onCtaMouseLeave);
            this.$refs.ctaBlock.addEventListener('mousedown', this.onCtaMouseDown);
            this.$refs.ctaBlock.addEventListener('mouseup', this.onCtaMouseUp);
        },

        onCtaMouseEnter() {
            this.$refs.ctaButton?.$el?.classList.add('hover');
        },

        onCtaMouseLeave() {
            this.$refs.ctaButton?.$el?.classList.remove('hover');
        },

        onCtaMouseDown() {
            this.$refs.ctaButton?.$el?.classList.add('active');
        },

        onCtaMouseUp() {
            this.$refs.ctaButton?.$el?.classList.remove('active');
        },

        removeCtaListeners() {
            if (!this.ctaSettings || !this.$refs.ctaBlock) return;

            this.$refs.ctaBlock.removeEventListener('mouseenter', this.onCtaMouseEnter);
            this.$refs.ctaBlock.removeEventListener('mouseleave', this.onCtaMouseLeave);
            this.$refs.ctaBlock.removeEventListener('mousedown', this.onCtaMouseDown);
            this.$refs.ctaBlock.removeEventListener('mouseup', this.onCtaMouseUp);
        },

        onCtaBlockClick(event) {
            if (this.cta.to) {
                this.$refs.ctaButton?.$el.click();
            } else {
                this.$emit('click', event);
            }
        },
        onCtaButtonClick(event) {
            event.stopPropagation();
        },
    },
};
</script>

<style lang="postcss" scoped>
.full-width {
    :deep(.a-picture__img) {
        width: 100%;
    }
}

.enlarged-image {
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        .a-media__type__screenshot {
            box-shadow: none;
        }
    }
}

.zoom-in {
    display: flex;
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: var(--av-fixed-lighter);
    border-radius: 4px;
    padding: 8px;
    transition: 0.2s all;

    .icon {
        fill: var(--av-inversed-primary);
    }
}

.a-media {
    &__type {
        &__screenshot {
            border-radius: 4px;
            overflow: hidden;
            transition: box-shadow 0.2s ease-in-out;
        }

        &__video {
            &:deep(.a-video-card__wrapper:before) {
                content: none;
            }
        }
    }
}

.no-shadow {
    .a-media__type__screenshot {
        box-shadow: none;
    }
}

.cta-block {
    position: absolute;
    inset: 0;
    display: flex;
    user-select: none;
    align-items: center;
    justify-content: center;

    &:deep(.a-button__content) {
        user-select: none;
    }
}

.cta-button {
    transition: box-shadow ease-in-out 0.2s;
}

.media-wrapper {
    user-select: none;
    position: relative;

    &--image.media-with-cta {
        .a-picture {
            transition: opacity 0.2s ease-in-out;
            will-change: opacity;
        }

        &:hover {
            cursor: pointer;

            .a-picture {
                opacity: .6;
            }
        }
    }

    &--screenshot.media-with-cta {
        .screenshot-wrapper {
            overflow: hidden;
            border-radius: 4px;
            border: 1px solid var(--av-brand-secondary-light, rgba(64, 139, 234, 0.3));
            box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
            transition: opacity .2s ease-in-out, box-shadow .2s ease-in-out;
            will-change: opacity;
        }

        .a-picture {
            transition: transform 0.2s ease-in-out;
            will-change: transform;
        }

        .cta-block {
            background: radial-gradient(50.09% 77.73% at 50.09% 50%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
        }

        &:hover {
            cursor: pointer;

            .screenshot-wrapper {
                box-shadow: none;
                opacity: 0.6;
            }

            .a-picture {
                transform: scale(1.02);
            }
        }
    }

    &--screenshot.media-with-enlarge {
        .screenshot-image {
            overflow: hidden;
            border-radius: 4px;
            border: 1px solid var(--av-brand-secondary-light, rgba(64, 139, 234, 0.3));
            box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
            transition: opacity 0.2s ease-in-out;
            will-change: opacity;
        }

        .a-picture {
            transition: transform 0.2s ease-in-out;
            will-change: transform;
        }

        .zoom-in {
            background: var(--av-fixed-lighter);
            &:hover {
                background: var(--av-button-main-hover);
                box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
            }
            &:active {
                background: var(--av-button-main-active);
                box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
            }
        }

        &:hover {
            cursor: pointer;

            .screenshot-image {
                box-shadow: none;
                opacity: 0.6;
            }

            .a-picture {
                transform: scale(1.02);
            }
        }
    }

    &--screenshot:not(.media-with-enlarge, .media-with-cta) {
        .screenshot-image {
            overflow: hidden;
            border-radius: 4px;
            border: 1px solid var(--av-brand-secondary-light, rgba(64, 139, 234, 0.3));
            box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
            transition: opacity 0.2s ease-in-out;
            will-change: opacity;
        }
    }

    &:focus-within {
        .media {
            outline: 3px solid var(--av-brand-secondary);
            outline-offset: -1px;
            border-radius: 4px;
        }
    }

    &:focus-visible {
        .media {
            outline: 3px solid var(--av-brand-secondary);
            outline-offset: -1px;
            border-radius: 4px;
        }
    }
    &:hover {
        .zoom-in {
            fill: var(--av-button-main);
            background: var(--av-button-main);
            box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
        }

        .cta-button {
            box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
        }
    }
    &:active {
        .zoom-in, .a-button {
            background: var(--av-button-main-active);
            box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
        }
    }
}
</style>
