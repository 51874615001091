<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import { trim } from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { HttpRejection } from '@model/http/rejection';
import { getOgImage, getTextDirection } from '@utils/html-meta';
import PagesComponent from './component.vue';

const API_SITE_ID = 3;

export default Vue.extend({
    name: 'PagesContainer',

    async serverPrefetch(): Promise<void> {
        // Init
        const { locale, pathMatch: slug, previewId } = this.$route.params;
        const previewKey = this.$route.query?.key;
        const state = this.$store.state.pages;

        // Disable LB cache for preview
        if (previewKey || previewId) {
            this.$ssrContext.res.headers.push({ key: 'Cache-Control', val: 'no-store' });
        }

        // Get page
        const pageRequestUrl = previewId ? `/api/utility/cache/${previewId}?time=${Date.now()}` : '/api/core/pages';
        const pageRequest = new ProductAPIQueryBuilder('page');
        pageRequest.setEntityPath(pageRequestUrl);

        if (!previewId) {
            pageRequest
                .setLocales([locale])
                .setPaginate(1, 1)
                .addMatchesAll('slug', '=', trim(slug, '/'))
                .addMatchesAll('website_id', '=', API_SITE_ID)
                .setCustomParam('page', previewKey ? { key: previewKey } : null)
                .setCustomParam('process-macros', '1');
        }

        const requestObj = pageRequest.toObject();
        await this.$store.dispatch('pages/getPage', { request: requestObj });

        await this.$store.dispatch('slices/getSyncedData', { slice: 's-list-form-elements', locale });

        if (state.httpStatus !== StatusCodes.OK) {
            throw new HttpRejection('Premature rendering stop', state.httpStatus);
        }

        if (!state.page?.id || !state.page?.body?.length) {
            throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
        }

        // Get linked entities
        const promises: Promise<void>[] = [];

        if (!state.page.settings?.hide_ribbon) {
            promises.push(this.$store.dispatch('ribbon/getAll', {
                locale,
                slug: state.page.slug,
                siteID: API_SITE_ID,
            }));
        }

        if (!state.page.settings?.hide_popup) {
            promises.push(this.$store.dispatch('popup/getAll', {
                locale,
                slug: state.page.slug,
                siteID: API_SITE_ID,
            }));
        }

        await Promise.all(promises);

        // Building page meta
        this.$ssrContext.res.meta = await this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(): any {
            const page = this.$store.state.pages.page;
            const { locale } = this.$route.params;

            const ensureTrailingSlash = (input: string) => input.replace(/\/*?$/, '/');

            const self = ensureTrailingSlash(`https://${this.$config.domain}${this.$route.path}/`);
            const canonical = page.meta?.canonical ? ensureTrailingSlash(page.meta.canonical) : self;
            const ogimage = getOgImage(page.meta?.image, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = page.meta?.title || 'Acronis';
            const description = page.meta?.description || title;
            const isIndexed = page.is_indexed;

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: page.meta?.['og:description'] || description },
                    { tag: 'meta', property: 'og:image', content: ogimage },
                    { tag: 'meta', property: 'og:url', content: self },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    {
                        tag: 'meta',
                        name: 'twitter:description',
                        content: page.meta?.['twitter:description'] || description,
                    },
                    { tag: 'meta', name: 'twitter:image', content: ogimage },
                    { tag: 'meta', name: 'twitter:url', content: self },
                    isIndexed ? null : { tag: 'meta', name: 'robots', content: 'noindex, nofollow' },
                    { tag: 'link', rel: 'image_src', href: ogimage },
                    isIndexed ? { tag: 'link', rel: 'canonical', href: canonical } : null,
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: locale.split('-')[0],
                },
                // ldJsonSchema,
            };
        },
    },

    render(h) {
        return h(PagesComponent);
    },
});
</script>
