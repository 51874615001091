<template>
    <div v-if="isFormReady" class="event-form" :class="className">
        <s-form-container :form="form" @submit="onSubmit" />
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';
import { DEFAULT_LANGUAGE } from '@model/const/events';

export default {
    name: 'EventForm',

    components: {
        SFormContainer,
    },

    props: {
        eventId: {
            type: Number,
            default: 0,
        },
        eventName: {
            type: String,
            default: '',
        },
        eventType: {
            type: String,
            default: '',
        },
        eventCategory: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        startDate: {
            type: String,
            default: '',
        },
        dateTitle: {
            type: Object,
            default: null,
        },
        buttonTitle: {
            type: String,
            default: '',
        },
        layout: {
            type: String,
            default: 'default',
        },
        successTitle: {
            type: String,
            default: '',
        },
        successText: {
            type: String,
            default: '',
        },
        showSuccessImage: {
            type: Boolean,
            default: true,
        },
        isThirdParty: {
            type: Boolean,
            default: false,
        },
        zoomMeetingId: {
            type: String,
            default: '',
        },
        marketoCampaignId: {
            type: String,
            default: '',
        },
        sfdcId: {
            type: String,
            default: '',
        },
        lmsExamUrl: {
            type: String,
            default: '',
        },
        languageName: {
            type: String,
            default: DEFAULT_LANGUAGE,
        },
        occurrences: {
            type: Array,
            default: () => [],
        },
        meetingDates: {
            type: Array,
            default: () => [],
        },
        trainings: {
            type: Array,
            default: () => [],
        },
        subscribers: {
            type: Array,
            default: () => [],
        },
    },

    emits: ['unlock', 'leadRequest', 'update'],

    data() {
        return {
            form: null,
            isFormReady: false,
        };
    },

    computed: {
        ...mapState({
            preset: (state) => state.slices.items?.['s-preset-form-events'] || null,
        }),

        isHybrid() {
            return this.eventType === 'hybrid';
        },

        className() {
            return {
                'columns-layout': this.layout === 'columns',
            };
        },

        audiencesCount() {
            const event = this.$store.state.events?.event?.items?.[0];
            return event?.audiences?.length || 0;
        },

        numberOfEmployeesIsShown() {
            const audiences = this.$store.state.events.event?.items?.[0]?.audiences;
            const targetAudienceIsBusiness = audiences?.length === 1 && audiences?.[0]?.title === 'business';
            const companyTypeIsCorpEndCustomer = this.form.company_type === 'Corporate End-Customer';
            return targetAudienceIsBusiness || companyTypeIsCorpEndCustomer;
        },

        showJobTitleField() {
            return (this.eventType === 'in-person' && !this.isThirdParty) || this.eventType === 'hybrid';
        },
    },

    beforeMount() {
        const fields = this.preset?.form?.registration?.fields;

        if (!fields) return;
        this.form = cloneDeep(this.preset.form);

        if (this.title) {
            this.form.registration.title = this.title;
        }

        if (this.dateTitle?.title) {
            this.form.registration.tooltip = {
                title: this.dateTitle.title,
                text: this.dateTitle?.timezoneTitle || '',
            };
        }

        if (this.buttonTitle) {
            this.form.registration.button.text = this.buttonTitle;
            if (this.form?.registration?.agreements) {
                this.form.registration.agreements.find((item) => item.name === 'light_gdpr').buttonName = this.buttonTitle;
            }
        }

        if (this.successTitle) {
            this.form.success.title = this.successTitle;
        }

        if (this.successText) {
            this.form.success.description = this.successText;
        }

        if (!this.showSuccessImage) {
            this.form.success.image = null;
            this.form.success.glyph = 'success';
        }

        if (this.audiencesCount > 1) {
            const comparison = (item) => item.name === 'company_type';
            const field = fields.find(comparison) || {};
            field.required = true;
            field.type = 'select';
        }

        if (this.numberOfEmployeesIsShown) {
            const comparison = (item) => item.name === 'of_Employees__c';
            const field = fields.find(comparison) || {};
            field.required = true;
            field.type = 'select';
            field.visible = null;
        }

        if (this.isHybrid) {
            // WEB-43713
            const comparison = (item) => item.name === 'attend_event';
            const field = fields.find(comparison) || {};
            field.required = true;
            field.type = 'select';
        }

        if (this.occurrences.length) {
            // WEB-44002
            const comparison = (item) => item.name === 'event_time';
            const field = fields.find(comparison) || {};
            field.required = true;
            field.type = 'select';
            field.options = this.occurrences.map((item) => ({ code: item.value, name: item.text }));
        }

        if (this.showJobTitleField) {
            // WEB-45222
            const comparison = (item) => item.name === 'job_title';
            const field = fields.find(comparison) || {};
            field.required = true;
            field.type = 'input';
        }

        if (this.eventType === 'hybrid' && this.languageName === 'Japanese') {
            // WEB-45627
            let field = fields.find((item) => item.name === 'jp_hyb_nw_dinner') || {};
            field.required = true;
            field.type = 'select';

            field = fields.find((item) => item.name === 'jp_hyb_learned_from') || {};
            field.required = true;
            field.type = 'select';

            field = fields.find((item) => item.name === 'jp_hyb_invited_by') || {};
            field.required = true;
            field.type = 'input';
        }

        if (this.eventType === 'in-person' && this.isThirdParty) {
            // WEB-45292
            const comparison = (item) => item.name === 'meeting_requested';
            let field = fields.find(comparison) || {};
            field.required = true;
            field.type = 'select';

            field = fields.find((item) => item.name === 'meeting_date') || {};
            field.required = true;
            field.type = 'select';
            field.options = this.meetingDates;
            field.options = this.meetingDates.map((item) => ({ code: item, name: item }));
        }

        if (this.trainings.length) {
            // WEB-45603
            const comparison = (item) => item.name === 'attend_training';
            let field = fields.find(comparison) || {};
            field.required = true;
            field.type = 'select';

            field = fields.find((item) => item.name === 'sessions') || {};
            field.required = true;
            field.type = 'select';
            field.options = this.trainings.map((item) => ({
                code: {
                    training_date_time: item.training_date_time,
                    training_name: item.training_name,
                    training_session_number: item.training_session_number,
                    training_sfdc_campaign_id: item.training_sfdc_campaign_id,
                },
                name: item.textValue,
            }));
        }

        if (!this.form.flow) {
            this.form.flow = {};
        }

        this.form.flow.marketo = {
            ...this.form.flow.marketo,
            event_category: this.eventCategory,
            event_id: this.eventId,
            event_name: this.eventName,
            event_type: this.eventType,
            lms_exam_url: this.lmsExamUrl,
            marketoCampaignId: this.marketoCampaignId,
            sfdc_id: this.sfdcId,
            zoomMeetingId: this.zoomMeetingId,
            eventPreviewToken: this.$route.params.key || null,
            mkto_notification_emails: this.subscribers.join(', ') || null,
        };

        if (!this.occurrences.length) {
            this.form.flow.marketo.event_time = this.startDate;
        }
    },

    mounted() {
        this.isFormReady = true;
    },

    updated() {
        this.$emit('update');
    },

    methods: {
        onSubmit(event) {
            this.$emit('unlock');
            this.$emit('leadRequest', {
                email: event.userData.email,
                osauid: event.response.data.osauid,
            });
        },
    },

};
</script>

<style lang="postcss" scoped>
.event-form {
    margin: 0 auto;
    max-width: 540px;

    &:deep(.s-form-container) {
        margin: 0;
        width: 100%;

        .a-button {
            color: var(--av-inversed-primary);
        }

        .el-form-item__error-holder {
            min-height: 24px;
        }
    }
}
</style>
<style lang="postcss">
.el-select-dropdown-events {
    z-index: 2147483647!important;
}
</style>
