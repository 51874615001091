<template>
    <div class="menu-tab-solutions">
        <menu-tab v-bind="$props" />
    </div>
</template>

<script lang="ts">
import MenuTabMixin from './menu-tab-mixin.vue';

export default {
    name: 'MenuTabSolutions',
    mixins: [MenuTabMixin],
};
</script>

<style lang="postcss" scoped>
.menu-tab-solutions {
    &:deep(.s-menu-tab) {
        @media (--viewport-desktop) {
            display: flex;
            overflow: hidden;
        }

        &__body {
            padding: 0 32px 8px;
            grid-column-gap: 16px;

            @media (--viewport-tablet) {
                display: flex;
                grid-column-gap: 16px;
            }

            @media (--viewport-desktop) {
                grid-column-gap: 32px;
                padding-inline-start: 16px;
            }

            @media (--viewport-desktop-wide) {
                padding-inline-start: 48px;
            }
        }

        &__section {
            padding: 32px 0 0;

            &:last-child {
                @media screen and (max-width: 767px) {
                    padding: 32px 0;
                }
            }

            @media (--viewport-tablet) {
                min-width: 540px;
                padding: 32px 0;
                flex-grow: 1;
                flex-shrink: 0;
            }

            &_divider_wide {
                padding: 32px 0;

                &:not(:last-child) {
                    @media screen and (max-width: 767px) {
                        &::before {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    padding-bottom: 48px;
                }

                @media (--viewport-tablet) {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 24px;
                        inset-inline-start: 100%;
                        background: var(--av-brand-secondary-light);
                        width: 1px;
                        height: calc(100% - 108px);
                    }
                }
            }

            &_submenu {
                @media (--viewport-tablet) {
                    border-top: 0;
                    border-inline-start: 1px solid var(--av-brand-accent);
                    padding-inline-start: 32px;
                    padding-top: 0;
                    margin-top: 32px;
                    margin-bottom: 90px;
                }

                &::before {
                    @media (--viewport-desktop) {
                        content: none;
                    }
                }
            }

            &_full-width {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: calc(100vw - 64px);
                border-top: 1px solid var(--av-brand-accent);

                @media (--viewport-desktop) {
                    padding-top: 24px;
                    grid-column: span 2;
                    margin-inline-end: 66px;
                    width: unset;
                }
            }

            &.s-menu-tab__section_tabindex_1 {
                @media (--viewport-tablet) {
                    min-width: 240px;
                }
            }
        }

        &__linktitle {
            pointer-events: none;

            .a-link__content {
                @mixin title-accent;
                color: var(--av-nav-primary);

                @media (--viewport-tablet) {
                    @mixin lead-accent;
                }
            }
        }

        &__list-item-link,
        &__linkmore {
            .a-link__content {
                @mixin lead;

                @media (--viewport-tablet) {
                    @mixin paragraph;
                }
            }
        }

        &__list {
            @media (--viewport-tablet) {
                flex-wrap: wrap;
                max-height: 260px;
                gap: 16px 24px;
                padding-inline-end: 16px;
            }

            @media (--viewport-desktop) {
                padding-inline-end: 32px;
            }

            @media (--viewport-desktop-wide) {
                padding-inline-end: 64px;
            }
        }
    }
}
</style>

<style lang="postcss">
.a-accordion-item--solutions {
    .content {
        height: auto !important;
    }

    &.active {
        .title {
            @media screen and (max-width: 767px) {
                position: relative;
                box-shadow: 0 4px 8px 0 rgba(36, 49, 67, 0.1);
            }
        }
    }
}
</style>
