/* eslint-disable max-len */
export const Figma = 'https://www.figma.com/file/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?type=design&node-id=38886-44853&mode=design&t=ZsqEOUcBqP9j74rV-4';

export const Default = {
    args: {
        translations: {
            title: 'Award-winning Data Protection Solutions',
            lead: 'from the Virtualization & Cloud Review',
            bodyText: 'Acronis is a recognized member of the cybersecurity industry',
            link: { text: 'See all awards' },
        },
        large: false,
        columns: 4,
        id: 'awards-story',
        padding: '',
        background: 'white',
        borderBottom: '',
        link: { to: '#' },
        award_ids: [10, 11, 12, 10, 11, 12, 10, 11, 12],
        showDescription: false,
    },
};

export const Large = {
    args: {
        translations: {
            awards: [
                { title: 'IDC MarketScape: Worldwide Cyber-Recovery Leader' },
                { title: 'Frost Radar™: Endpoint Security Leader' },
                { title: 'CRN Security 100 List 2024' },
                { title: 'Gold medal for Endpoint protection 2021' },
                { title: 'OPSWAT Platinum certification for anti-malware' },
                { title: 'G2 Leader in Cloud Email Security' },
                { title: 'Cloud Security Alliance member' },
                { title: 'ICSA Labs endpoint anti-malware certified' },
                { title: 'Anti-Malware Testing Standards Organization member' },
                { title: 'VirusTotal member' },
            ],
            link: { text: 'See all awards' },
            title: 'Powered by industry-recognized, award-winning endpoint protection',
            lead: 'from the Virtualization & Cloud Review',
            bodyText: 'Acronis is a recognized member of the cybersecurity industry',
        },
        large: true,
        columns: 5,
        awards: [
            {
                image: '@076a33c2e38b3b4c2398c39a2722977c',
            },
            {
                image: '@e7227d7c1792d079546107d71a1c4ce9',
                url: 'https://www.acronis.com/en-us/resource-center/resource/acronis-named-a-growth-and-innovation-leader-in-frost-radar-endpoint-security-2023/',
            },
            {
                image: '@16603cdb5e4be470d5f889ea047627ae',
                url: 'https://www.acronis.com/en-us/blog/posts/acronis-named-in-2024-crn-security-100-list-for-the-third-consecutive-year/',
            },
            {
                image: '@ecb14bade058ce5e034c23dc9d3d1f91',
                url: 'https://dl.acronis.com/u/rc/White-Paper-Acronis-Cyber-Protect-Acronis-Impact-Report-EN-US.pdf',
            },
            {
                image: '@41d9304fc273304cec832820001056b3',
                url: 'https://www.opswat.com/blog/opswat-access-control-certification-oaccp-update-for-july-2021',
            },
            {
                image: '@885f94eee64b6d148f88797d66c25c35',
                url: 'https://www.acronis.com/en-us/blog/posts/acronis-named-a-leader-in-g2-grid-for-cloud-email-security-for-small-business/',
            },
            {
                image: '@ee50cd00be1f2d7152ca1c202b76492b',
                url: 'https://cloudsecurityalliance.org/star/registry/acronis-international-gmbh',
            },
            { image: '@286f9f6916a4084928e874f795ff959d' },
            { image: '@ecb14bade058ce5e034c23dc9d3d1f91' },
            { image: '@3bf3b46c01310365dd5418e4fa905cb1' },
        ],
        link: { to: '#' },
        padding: '',
        background: 'white',
        borderBottom: '',
        id: 'awards-story',
    },
};
