<template>
    <div :class="className">
        <div class="a-container">
            <s-workloads
                :settings="settings"
                @changeWorkload="$emit('changeWorkload', $event)"
                @toggleCart="$emit('toggleCart', $event)"
            />

            <p v-if="settings.dictionary?.description" class="promo-description">
                {{ settings.dictionary.description }}
            </p>
            <p v-else-if="promo.isPromoActive && promo?.description" class="promo-description">
                <a-glyph v-if="promo?.glyph" :name="promo?.glyph" size="m" /> {{ promo.description }}
            </p>

            <s-editions
                :settings="settings"
                @toggleCart="$emit('toggleCart', $event)"
                @changeItem="$emit('changeItem', $event)"
                @changeQuantity="$emit('changeQuantity', $event)"
                @changeLoading="$emit('changeLoading', $event)"
            />
            <p v-if="settings.isVisibleCloudLink" class="link-card">
                <a-link size="paragraph" :text="textLink" @click="onClick()" />
            </p>
        </div>

        <div v-if="footer" class="footer">
            <div v-if="footer.title" v-html="footer.title" />
            <a-link v-if="footer.link" v-bind="footer.link" />
        </div>
        <s-awards v-if="awards.length" :award_ids="awardIDs" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import analytics from '@core/mixins/analytics.js';
import currency from '@core/mixins/currency.js';
import SAwards from '@core/slices/pages/awards/awards.vue';
import SEditions from './components/editions.vue';
import SWorkloads from './components/workloads.vue';
import mixin from './mixin.js';

export default {
    name: 'SProducts',

    components: {
        AGlyph,
        ALink,
        SAwards,
        SEditions,
        SWorkloads,
    },

    mixins: [mixin, analytics, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['toggleCart', 'changeWorkload', 'changeItem', 'changeQuantity', 'toggleProduct', 'changeLoading'],

    computed: {
        ...mapState({
            awards: (state) => state.awards.items || {},
        }),

        className() {
            return [
                's-purchasing-acp-products',
                { 'has-border': this.settings?.page?.hasBorder },
                { 'has-background': this.settings?.page?.hasBackground },
            ];
        },

        footer() {
            return this.settings.dictionary?.expert?.footer || null;
        },

        awardIDs() {
            return this.settings?.dictionary?.expert?.awards || [];
        },

        textLink() {
            return this.translation('I want to add Cloud Storage and/or Disaster Recovery');
        },

        promo() {
            return this.settings.promo;
        },
    },

    methods: {
        onClick() {
            this.addCloud();
            this.$emit('toggleCart', 'cloud');
        },
    },

    serverPrefetch() {
        if (!this.awardIDs.length) return;
        this.awardIDs.forEach((aid) => this.$store.dispatch('awards/getAward', aid));
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp-products {
    padding-top: 96px;
    &:deep(.s-awards) {
        background-color: rgba(64, 139, 234, 0.05);
        .list {
            gap: 64px;
            flex-direction: column;
            @media (--viewport-tablet) {
                flex-wrap: nowrap;
                flex-direction: row;
            }
            .item {
                max-width: unset;
                @media (--viewport-tablet) {
                    width: 50%;
                }
            }
            .award {
                align-items: center;
                flex-direction: column-reverse;
                @media (--viewport-tablet) {
                    height: 100%;
                    justify-content: space-between;
                }
                @media (--viewport-desktop) {
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                }
            }
            .title {
                @mixin lead;
                font-weight: 400;
                text-align: center;
                @media (--viewport-desktop) {
                    width: 50%;
                    text-align: start;
                }
            }
            .image-wrapper {
                @media (--viewport-desktop) {
                    width: 50%;
                }
            }
            .image {
                height: 80px;
            }
        }
    }
}

.has-border {
    border-bottom: 2px solid var(--av-brand-secondary-light);
}

.has-background {
    background: var(--av-gradient-to-top-lightest);
}

.promo-description {
    @mixin paragraph-accent;
    text-align: center;
    margin: 8px 0 -16px;
    padding-inline-start: 32px;
    color: rgba(124, 155, 30, 1);

    .a-glyph {
        margin: 0 8px;
        margin-inline-start: -32px;
        fill: rgba(124, 155, 30, 1);
    }
}

.link-card {
    max-width: 400px;
    margin: 0 auto 12px;
    text-align: center;

    @media (--viewport-desktop) {
        max-width: none;
    }

    &:deep(.a-link) {
        &__content {
            @mixin title-accent;
            font-size: 22px;
            text-align: center;
            color: var(--av-brand-primary);
        }
    }
}

.footer {
    @mixin body;
    gap: 8px;
    display: flex;
    padding: 16px;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--av-nav-primary);
    background: var(--av-brand-secondary-light);

    @media (--viewport-tablet) {
        flex-wrap: nowrap;
    }

    &:deep(.a-link) {
        .a-link__content {
            @mixin note-heading;
            gap: 8px;
            display: flex;
            padding: 4px 16px;
            white-space: nowrap;
            border-radius: 12px;
            align-items: center;
            flex-direction: row-reverse;
            color: var(--av-fixed-info-dark);
            border: 1px solid var(--av-fixed-info);
            background: var(--av-fixed-info-accent);
        }

        .a-glyph {
            margin: 0;
            top: auto;
            position: relative;
            margin-inline-start: unset;
            fill: var(--av-fixed-info-dark);
        }
    }
}
</style>
