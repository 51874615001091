/* eslint-disable max-len */
export const Figma = 'https://www.figma.com/design/lbSbqOvDb3vf2tbPCZexXP/Cloud-Demo-Center-page-updates?node-id=77-4300&t=geJxoL2JScJLxCS1-0';

export const Default = {
    args: {
        title: 'Acronis Demo Library: Watch videos on features and use cases',
        tabs: [
            {
                anchorId: 'one',
                name: 's-video-cards',
                label: '“Under 10min” overviews',
                data: {
                    showMoreText: 'More videos',
                    showLessText: 'Show less',
                    cards: [
                        {
                            id: 'video-69',
                            title: 'Acronis Cyber Protect Cloud Overview',
                            desc: 'Discover how one solution simplifies cyber protection to ensure clients are secure and you are profitable.',
                            time: '9:17',
                            ytId: 'xDFAXX4lXkA',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '6.6K views',
                        },
                        {
                            id: 'video-70',
                            title: 'Advanced Backup',
                            desc: 'Learn how you can easily expand your backup capabilities, support more workloads, and automate more tasks.',
                            time: '5:27',
                            ytId: 'FajxLkBYF58',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '1.7K views',
                        },
                        {
                            id: 'video-71',
                            title: 'Security',
                            desc: 'See how you can stop more cyberthreats with fewer resources to provide superior endpoint protection.',
                            time: '9:12',
                            ytId: 'hL1953ungUU',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '301 views',
                        },
                        {
                            id: 'video-72',
                            title: 'Disaster Recovery',
                            desc: 'Eliminate client downtime and protect their workloads by adding disaster recovery services quickly and easily.',
                            time: '6:06',
                            ytId: 'jXbC5Z6xlLM',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '285 views',
                        },
                        {
                            id: 'video-73',
                            title: 'Management',
                            desc: 'Find the secret to streamlining your efforts with automated patch management and multiple management tools.',
                            time: '6:38',
                            ytId: 'gswA_bO05E0',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '709 views',
                        },
                        {
                            id: 'video-74',
                            title: 'Email security',
                            desc: 'Block email threats, including spam, phishing, BEC, APTs, and zero-days before they reach end-users.',
                            time: '4:04',
                            ytId: '4M-EFiUo6bs',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '68 views',
                        },
                        {
                            id: 'video-75',
                            title: 'Hybrid DR',
                            desc: 'Enables service providers to deliver full-featured, advanced DRaaS from both the Acronis Cloud and a local datacenter–with the power to direct individual client data to the most optimal environment.',
                            time: '6:28',
                            ytId: 'Ftsc_o2NdvQ',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '359 views',
                        },
                        {
                            id: 'video-76',
                            title: 'Data Loss Prevention (DLP)',
                            desc: 'DLP empowers you with unmatched provisioning and management simplicity, to prevent data leakage from clients’ workloads via peripheral devices and network communications.',
                            time: '4:57',
                            ytId: 'najNfjrjT2g',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '951 views',
                        },
                    ],
                },
            },
            {
                name: 's-video-cards',
                label: 'Account management',
                data: {
                    cards: [
                        {
                            id: 'video-1',
                            title: 'Service provider UI',
                            desc: 'In this video, we’ll have a look at the Acronis Cyber Protect Cloud user interface from the perspective of a managed service provider.',
                            time: '8:39',
                            ytId: '0LDwFai0WWs',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '19K views',
                        },
                        {
                            id: 'video-2',
                            title: 'Creating the first customer',
                            desc: 'Watch this demo video to learn how to add your first customer to the system.',
                            time: '3:38',
                            ytId: 'qzl3WyETayE',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '4.5K views',
                        },
                        {
                            id: 'video-3',
                            title: 'Adding an admin',
                            desc: 'Now that you’ve added a customer to the system, it’s time to add an administrator for that customer.',
                            time: '1:30',
                            ytId: 'yOeTH8H2NYg',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '5.9K views',
                        },
                        {
                            id: 'video-4',
                            title: 'Adding the first device',
                            desc: 'With a customer and an admin in the system, you’re ready to add your first device.',
                            time: '1:41',
                            ytId: 'kkuANHozYCo',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '11K views',
                        },
                        {
                            id: 'video-5',
                            title: 'How to install an agent on Mac',
                            desc: 'In this video, you’ll learn how to install the Acronis Cyber Protect Cloud agent on a Mac device.',
                            time: '2:27',
                            ytId: 'dcNZIgyTUJ0',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '8.5K views',
                        },
                        {
                            id: 'video-6',
                            title: 'Creating the first protection plan',
                            desc: 'After you have added your first customer, admin, and device, you can create a protection plan tailored to customer’s needs.',
                            time: '3:08',
                            ytId: 'W3LpC4AZLY4',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '4K views',
                        },
                        {
                            id: 'video-7',
                            title: 'White labelling and branding',
                            desc: 'In this video, we’ll take a look at the branding and white labelling options available in the Acronis Cyber Protect Cloud platform.',
                            time: '1:39',
                            ytId: 'QUY7mGsloc0',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '5.6K views',
                        },
                        {
                            id: 'video-8',
                            title: '2FA',
                            desc: 'Watch this demo video to learn how to enable two-factor authentication for an additional layer of security.',
                            time: '1:29',
                            ytId: 'wvAx8HHs36o',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '8.7K views',
                        },
                        {
                            id: 'video-9',
                            title: 'Setting up notifications',
                            desc: 'Learn how you can set up alerts and notifications for process failures and successes, quota overages, usage reports, and more.',
                            time: '1:10',
                            ytId: 'OXd-IUHqVUI',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '8.1K views',
                        },
                        {
                            id: 'video-10',
                            title: 'Customer UI',
                            desc: 'In this video, we’ll have a look at the Acronis Cyber Protect Cloud user interface from the perspective of an end customer.',
                            time: '5:56',
                            ytId: 'N7PAY4nS8qU',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '8.9K views',
                        },
                        {
                            id: 'video-11',
                            title: 'Setting up and configuring the Acronis Cyber Infrastructure',
                            desc: 'Explore Acronis Cyber Infrastructure’s installation and basic configuration process.',
                            time: '22:32',
                            ytId: 'fkYxcomwveQ',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '13K views',
                        },
                        {
                            id: 'video-12',
                            title: 'Unattended agent installation',
                            desc: 'In this video, we walk you through remote installation of the backup agent for Windows machines.',
                            time: '4:10',
                            ytId: 'BKHfdclL4tA',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '7.6K views',
                        },
                        {
                            id: 'video-13',
                            title: 'Adjust protection with CPOC alerts',
                            desc: 'Learn how to use Acronis Cyber Protection Operation Centers (CPOC) alerts and protection recommendations to mitigate risks from upcoming and existing cyberthreats with Acronis Cyber Protect Cloud.',
                            time: '2:01',
                            ytId: 'EYo57KtxNyY',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '1.9K views',
                        },
                        {
                            id: 'video-14',
                            title: 'Migrate from Symantec endpoint protection to Acronis Cyber Protect Cloud',
                            desc: 'Learn how to upgrade security and earn more by migrating from Symantec Endpoint Protection to Acronis Cyber Protect Cloud.',
                            time: '11:57',
                            ytId: 'r00t2GHMTbo',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '1.6K views',
                        },
                    ],
                },
            },
            {
                name: 's-video-cards',
                label: 'Security',
                data: {
                    cards: [
                        {
                            id: 'video-15',
                            title: 'Acronis Active Protection',
                            desc: 'Learn how Acronis Active Protection actively stops ransomware in real-time with advanced, AI-based anti-ransomware technology. And, just in case files are impacted before an attack is deflected, backups are automatically restored.',
                            time: '3:22',
                            ytId: 'fRP9qWxBLvY',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '4.6K views',
                        },
                        {
                            id: 'video-16',
                            title: 'Real-time malware protection',
                            desc: 'Explore our real-time malware protection, allowing you to identify and block attacks on-the-fly – and not just from well-known malware, like WannaCry, but also new and zero-day instances detected by Acronis’ Cyber Protection Operation Center.',
                            time: '2:52',
                            ytId: '9PtvjHbPeU0',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '4.4K views',
                        },
                        {
                            id: 'video-17',
                            title: 'Scheduled malware scan',
                            desc: 'Learn how to perform scheduled malware scans and drastically reduce the load on endpoints and networks with Acronis Cyber Protect Cloud.',
                            time: '2:06',
                            ytId: 'YubQg5ZG8tg',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2.8K views',
                        },
                        {
                            id: 'video-18',
                            title: 'Patch management',
                            desc: 'In this video, we explore the centralized patch management feature both partners and customers can access with Acronis Cyber Protect Cloud.',
                            time: '4:15',
                            ytId: 'FEE5doippr0',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '18.1K views',
                        },
                        {
                            id: 'video-19',
                            title: 'Patch management: flexible updates',
                            desc: 'Experience a sample patch management scenario within a protection plan: patching specific high and critical severity updates exclusively for Windows Server 2016.',
                            time: '4:35',
                            ytId: '68lG1OL9UrA',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '3.3K views',
                        },
                        {
                            id: 'video-20',
                            title: 'Do not reboot if backup is running',
                            desc: 'Watch this video to learn how to use the "Do not reboot if the backup is running" feature available within the Patch Management module in a protection plan.',
                            time: '1:53',
                            ytId: 'VxWWsIZSNs0',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '3K views',
                        },
                        {
                            id: 'video-21',
                            title: 'Pre-update backup',
                            desc: 'In this video, we demonstrate how to use the "pre-update backup" feature within the Patch Management module available in Acronis Cyber Protect Cloud.',
                            time: '2:07',
                            ytId: 'd5Xi6hSTNcs',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2.9K views',
                        },
                        {
                            id: 'video-22',
                            title: 'Using an agent for P2P updating',
                            desc: 'Learn how to use an agent to download and distribute patches and updates using peer-to-peer technology.',
                            time: '1:34',
                            ytId: 'y2v_ZYwWP-U',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '1.7K views',
                        },
                        {
                            id: 'video-23',
                            title: 'Zero-day vulnerability protection',
                            desc: 'In this video, we demonstrate how to protect your customers and endpoints from zero-day vulnerabilities by examining an email with an attached, malware-infected PDF.',
                            time: '2:37',
                            ytId: 'Cz7WsX-knmA',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '3.7K views',
                        },
                        {
                            id: 'video-24',
                            title: 'Allow list automation',
                            desc: 'Learn how to automate the process of adding applications to an allow list, prevent false-positive detections, and enhance scanning performance.',
                            time: '4:21',
                            ytId: 'QOeAa8RcFtk',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2.8K views',
                        },
                        {
                            id: 'video-25',
                            title: 'URL filtering',
                            desc: 'In this video, we show how Acronis Cyber Protect Cloud allows you to easily build URL filtering programs to detect and block malicious websites.',
                            time: '2:33',
                            ytId: '5ovNGOFAUao',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '4K views',
                        },
                        {
                            id: 'video-26',
                            title: 'URL categorization',
                            desc: 'Learn how to utilize our URL categorization option – there are 44 website categories you can configure access to: either allowing or denying access to the specific sites in a category.',
                            time: '2:05',
                            ytId: 'aKFioKRqmRU',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2.1K views',
                        },
                        {
                            id: 'video-27',
                            title: 'Third-party antivirus',
                            desc: 'Get step-by-step guidance on how to use Acronis Cyber Protect Cloud with your current third-party antivirus, proactively protect your clients with our built-in anti-malware and antivirus protection, or use our protection alongside your existing solution.',
                            time: '2:32',
                            ytId: 'kFRIuucdJIk',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2.5K views',
                        },
                        {
                            id: 'video-28',
                            title: 'RanSim ransomware simulator test',
                            desc: 'In this video, we’ll demonstrate the Acronis Active Protection’s capabilities, utilizing the ransomware simulator tool RanSim.',
                            time: '1:36',
                            ytId: '9bnK4vH_Tzc',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '4K views',
                        },
                        {
                            id: 'video-29',
                            title: 'VB100 test certification',
                            desc: 'In this video, we’ll discuss our test results from the Virus Bulletin #VB100. Acronis Cyber Protect Cloud showed a 100% detection result.',
                            time: '2:07',
                            ytId: 'wFhMos1FxXM',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2K views',
                        },
                    ],
                },
            },
            {
                name: 's-video-cards',
                label: 'Backup and Recovery',
                data: {
                    cards: [
                        {
                            id: 'video-80',
                            title: 'Geo-redundand storage',
                            desc: 'Learn how to enable and use Acronis Geo-Redundant Storage - an additional protection for end-customers and partners with highest business continuity or compliance requirements',
                            time: '5:32',
                            ytId: 'yBGBOZwuamc',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '274 views',
                        },
                        {
                            id: 'video-79',
                            title: 'Backup replication',
                            desc: 'Learn how to replicate backups to multiple local/network locations or to Acronis Cloud.',
                            time: '6:00',
                            ytId: '3k_PhszdPhI',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '86 views',
                        },
                        {
                            id: 'video-78',
                            title: 'Backup validation',
                            desc: 'Explore how to configure various backup validation methods with Backup.',
                            time: '5:58',
                            ytId: '1iYeek2Fs0U',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '177 views',
                        },
                        {
                            id: 'video-77',
                            title: 'Group management for Microsoft 365',
                            desc: 'Save time and effort with streamlined protection management for multiple Microsoft 365 workloads, eliminating the need to configure protection for each individual workload.',
                            time: '10:14',
                            ytId: 'SXaMH8xDHh8',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '153 views',
                        },
                        {
                            id: 'video-30',
                            title: 'Agent-based backups of Windows machines',
                            desc: 'With this video, you’ll learn how to install the Windows agent in a typical Windows machine.',
                            time: '3:20',
                            ytId: 'BhfZdQI0SDg',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '13K views',
                        },
                        {
                            id: 'video-31',
                            title: 'Backing up a Linux machine',
                            desc: 'Learn how to download the agent for Linux and apply a protection plan for the newly added devices.',
                            time: '4:14',
                            ytId: 'mGaGNODej5w',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '14K views',
                        },
                        {
                            id: 'video-32',
                            title: 'Backup capabilities overview',
                            desc: 'In this video, we share an overview of the backup and recovery capabilities that you can utilize to keep your data safe.',
                            time: '9:19',
                            ytId: '2MtwYhBDaE4',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '13K views',
                        },
                        {
                            id: 'video-33',
                            title: 'Configure retention rules',
                            desc: 'Learn how to properly configure your retention policy based on the backup schedule you set in a protection plan.',
                            time: '4:06',
                            ytId: 'Me1J2NIabAE',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '4.8K views',
                        },
                        {
                            id: 'video-34',
                            title: 'Continuous data protection',
                            desc: 'Explore our sophisticated continuous data protection capabilities to lock in safe and instant remediation and near-zero RPOs.',
                            time: '2:31',
                            ytId: 'TqAxWDvGwbI',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '7.6K views',
                        },
                        {
                            id: 'video-35',
                            title: 'Acronis Active Protection',
                            desc: 'In this video, we guide you through Acronis’ unique anti-ransomware technology that proactively protects your data by using AI to prevent and detect all types of ransomware.',
                            time: '3:22',
                            ytId: 'fRP9qWxBLvY',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '4.6K views',
                        },
                        {
                            id: 'video-36',
                            title: 'Forensic backup',
                            desc: 'Explore our forensic backup capability and learn how it allows you to simplify analysis by collecting digital evidence (e.g. memory dumps and process information) from disk-level backups.',
                            time: '5:12',
                            ytId: 'FjS16_mrZe8',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '5.2K views',
                        },
                        {
                            id: 'video-37',
                            title: 'Safe endpoint recovery',
                            desc: 'Learn how to prevent the recurrence of infections by using integrated malware scanning and malware deletion during the recovery process.',
                            time: '2:43',
                            ytId: '1MKAciAt_m0',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '3.5K views',
                        },
                        {
                            id: 'video-38',
                            title: 'Data protection map',
                            desc: 'Improve your cyber protection posture with automatic data classification to track the protection status of important files and ensure no key data is left unprotected.',
                            time: '2:36',
                            ytId: 'd4H9_fjyA-k',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '4K views',
                        },
                        {
                            id: 'video-39',
                            title: 'Instant restore for physical and VM',
                            desc: 'Utilize Acronis Instant Restore to get your physical and virtual machines back up and running in as little as fifteen seconds.',
                            time: '1:42',
                            ytId: 'w4l9Z_n89Gc',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '12K views',
                        },
                        {
                            id: 'video-40',
                            title: 'Backup and recovery of Microsoft 365',
                            desc: 'In this video, we show how to back up and restore your Microsoft 365 data from the cyber protection management console.',
                            time: '9:09',
                            ytId: '9xUmChqmNy4',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '22K views',
                        },
                        {
                            id: 'video-41',
                            title: 'Microsoft Teams backup and recovery',
                            desc: 'Learn the basics of backup and recovery operations for Microsoft Teams – from adding a Microsoft 365 organization to performing granular recovery of specific items.',
                            time: '5:27',
                            ytId: 'zR18xO9Uexs',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '7.7K views',
                        },
                        {
                            id: 'video-42',
                            title: 'backup options',
                            desc: 'In this video, we cover advanced backup options available in your protection plan, including backup validation, file filters, forensic options, compression level, and many more.',
                            time: '10:49',
                            ytId: 'zDhKy-f0vd8',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '8.6K views',
                        },
                        {
                            id: 'video-43',
                            title: 'Setting up Amazon S3 as a backup destination',
                            desc: 'In this video, we guide you through the process of creating an Acronis backup gateway from the Acronis Cyber Infrastructure in just a few simple steps.',
                            time: '5:04',
                            ytId: 'bE5b6dEfUuE',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '5.2K views',
                        },
                        {
                            id: 'video-44',
                            title: 'Setting up NFS as a backup destination',
                            desc: 'Learn how to configure an NFS backup storage - from selecting an available node to creating a secure gateway.',
                            time: '1:30',
                            ytId: 'ZUEQTkCiM9o',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2.9K views',
                        },
                        {
                            id: 'video-45',
                            title: 'Enabling agent-less VMware backup',
                            desc: 'In this video, we install the virtual appliance for your ESXi hosts. This demo is focused on the virtual appliance (OVF).',
                            time: '4:09',
                            ytId: 'A8iXtpyBobQ',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '13K views',
                        },
                        {
                            id: 'video-46',
                            title: 'Enabling agent-less Hyper-V backup',
                            desc: 'In this demo, we install the Hyper-V agent on our host, allowing us to protect virtual machines from the host level without an agent.',
                            time: '3:04',
                            ytId: 'QOItPyLqzOo',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '9.4K views',
                        },
                        {
                            id: 'video-47',
                            title: 'Setting up and configuring Acronis Cyber Infrastructure',
                            desc: 'Learn how to install and configure Acronis Cyber Infrastructure - our scale-out, cost-efficient, and multi-purpose infrastructure solution for cyber protection.',
                            time: '22:32',
                            ytId: 'fkYxcomwveQ',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '13K views',
                        },
                        {
                            id: 'video-48',
                            title: 'Setting up Acronis Cyber Infrastructure as a backup destination',
                            desc: 'Learn how to set up Acronis Cyber Infrastructure as a backup destination, includes tips on configuring a network, setting volume parameters, DNS configuration, and registering a backup gateway.',
                            time: '3:11',
                            ytId: 'jDvhixDkdQQ',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2.5K views',
                        },
                        {
                            id: 'video-49',
                            title: 'Disaster recovery basics',
                            desc: 'In this video, we share an overview of Acronis Cyber Disaster Recovery Cloud – including both failover and failback scenarios.',
                            time: '2:14',
                            ytId: '0G_KZgPBlY0',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '7.9K views',
                        },
                        {
                            id: 'video-50',
                            title: 'Disaster recovery connectivity options',
                            desc: 'Explore an overview of your options for disaster recovery connectivity to the cloud site - including point-to-site remote VPN access, site-to-site VPN connection, and a cloud-only mode.',
                            time: '5:46',
                            ytId: 'myKFVndpvPw',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '5.4K views',
                        },
                        {
                            id: 'video-51',
                            title: 'P2S VPN access to local site',
                            desc: 'In this video, you’ll learn how to configure the point-to-site remote VPN access to the local site by using Acronis Cyber Disaster Recovery Cloud.',
                            time: '5:35',
                            ytId: 'p3RDUB5UQSY',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '5.4K views',
                        },
                        {
                            id: 'video-52',
                            title: 'Site-to-site connectivity',
                            desc: 'Learn how to add site-to-site VPN connections between your local and cloud sites suitable for disaster recovery scenarios when you have tightly dependent servers on the local site.',
                            time: '2:26',
                            ytId: 'QPaeMujV3J8',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '5.5K views',
                        },
                        {
                            id: 'video-53',
                            title: 'Create recovery and perform failover',
                            desc: 'In this video, we show how to create a recovery server, covering all the necessary disaster recovery prerequisites and going through the process of performing a failover.',
                            time: '10:02',
                            ytId: 'ygFcqnrXrn4',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '9.5K views',
                        },
                        {
                            id: 'video-54',
                            title: 'Disaster recovery failback',
                            desc: 'Learn how to perform a failback in our disaster recovery solution and how to move the workload from the cloud back to your premises.',
                            time: '2:59',
                            ytId: 'rsNRqWWvMBo',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '3.2K views',
                        },
                        {
                            id: 'video-55',
                            title: 'Runbook configuration',
                            desc: 'In this video, we guide you through the process of configuring a runbook for failover of servers. Learn how to add specific steps and actions, edit parameters, and more.',
                            time: '4:02',
                            ytId: '0TgpS75JGQ4',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2.4K views',
                        },
                        {
                            id: 'video-56',
                            title: 'RPO monitoring',
                            desc: 'Learn how to monitor the recovery point objective (RPO) for both recovery and primary servers within the Acronis Cyber Protect Cloud management console.',
                            time: '2:22',
                            ytId: '2xspgGS9U1g',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '1.4K views',
                        },
                        {
                            id: 'video-57',
                            title: 'Recovery from bootable media',
                            desc: 'Learn how to recover a bootable ISO file – from downloading the ISO image to registering the media and performing the recovery.',
                            time: '3:21',
                            ytId: 'WWtRcn1lK0s',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '29K views',
                        },
                    ],
                },
            },
            {
                name: 's-video-cards',
                label: 'Protection management',
                data: {
                    cards: [
                        {
                            id: 'video-58',
                            title: 'Remote access to customer infrastructure',
                            desc: 'Learn how to securely and remotely log in to a customer’s machine and assist remote users with zero network configuration.',
                            time: '3:06',
                            ytId: 'uFGIsFAkjjY',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2.4K views',
                        },
                        {
                            id: 'video-59',
                            title: 'Remote desktop connection without VPN',
                            desc: 'In this video, we guide you through the process of setting up a remote desktop connection without a VPN.',
                            time: '2:20',
                            ytId: 'XzgLMdhqjj8',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '3.6K views',
                        },
                        {
                            id: 'video-60',
                            title: 'Protection plans for remote workers',
                            desc: 'Explore the pre-configured plans available by default for remote and office workers, including continuous data protection, full scan protection for vulnerabilities, daily patch management, and more.',
                            time: '0:56',
                            ytId: 'qHP48Un6F_s',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '4.2K views',
                        },
                        {
                            id: 'video-61',
                            title: 'Remote wipes for remote workloads',
                            desc: 'Make sure customer data doesn’t fall into someone else’s hands. In this video, we show you how to use Acronis’ Remote Wipe feature to delete data from a lost or otherwise compromised device.',
                            time: '0:58',
                            ytId: 'psiJ0IXDAG0',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2.8K views',
                        },
                        {
                            id: 'video-62',
                            title: '#CyberFit score for remote workloads',
                            desc: 'Evaluate the individual security state of each machine you manage with Acronis Cyber Protect Cloud. In this video, we show you how to find and use a device’s Acronis #CyberFit score to improve security and better protect your workforce.',
                            time: '3:48',
                            ytId: 'v1wbf7VqzSg',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '1.5K views',
                        },
                        {
                            id: 'video-63',
                            title: 'RDP remote connection to corporate network',
                            desc: 'Easily and securely gain remote access to employee devices with Acronis Cyber Protect Cloud. In this video, we show you how to organize and share protected remote desktop connections for each of your protected devices.',
                            time: '0:51',
                            ytId: 'sTHgJhP_z4g',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '4.2K views',
                        },
                    ],
                },
            },
            {
                name: 's-video-cards',
                label: 'Integrations',
                data: {
                    cards: [
                        {
                            id: 'video-64',
                            title: 'ConnectWise Manage Integration',
                            desc: 'Learn how to set up the integration of Acronis Cyber Protect Cloud  with ConnectWise Manage to provision services to both new and existing customers, automate billing, monitor and automatically create tickets for failed backup and recovery operations.',
                            time: '6:18',
                            ytId: 'LyFVWcu7MME',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2.2K views',
                        },
                        {
                            id: 'video-65',
                            title: 'ConnectWise Automate Integration',
                            desc: 'Learn how to leverage your existing RMM tool while proactively protecting clients’ data. See how easy it is to set up backups, monitor activities and alerts, and provision Acronis Cyber Protect Cloud services through the ConnectWise interface.',
                            time: '9:04',
                            ytId: '2sV0ne2dCR4',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '1.9K views',
                        },
                        {
                            id: 'video-66',
                            title: 'ConnectWise Control Integration',
                            desc: 'In this video, we show how to remotely install and update backup agents, apply backup plans and active protection plans to customer machines, and monitor statuses and alerts.',
                            time: '4:10',
                            ytId: '8eMkJ-2Wf8A',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '929 views',
                        },
                        {
                            id: 'video-67',
                            title: 'ConnectWise Control v.1.1 integration',
                            desc: 'Discover the most important changes and improvements in version 1.1 of the integration between Acronis Cyber Protect Cloud and ConnectWise Control.',
                            time: '6:14',
                            ytId: '1XMT0XP9vuE',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '1K views',
                        },
                        {
                            id: 'video-68',
                            title: 'Integration with WHMCS',
                            desc: 'Watch this video to learn how to automate billing and provisioning of Acronis Cyber Protection Solutions, including backup, file sync and share, notary, and disaster recovery services, through WHMCS.',
                            time: '6:01',
                            ytId: 'WdvM1M3iuIQ',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '2K views',
                        },
                        {
                            id: 'video-69',
                            title: 'Integration with Kaseya VSA',
                            desc: 'Learn how to centrally monitor and manage all of your Acronis workloads and alerts within the Kaseya VSA interface, and leverage Acronis’ policy management features to ensure gapless protection.',
                            time: '9:18',
                            ytId: 'cwNoObeSoLM',
                            image: '/images/video_car/video_car',
                            imageAlt: 'video',
                            viewsText: '463 views',
                        },
                    ],
                },
            },
        ],
    },
};
