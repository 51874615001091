/* eslint-disable max-len */
import Vue from 'vue';
import SAwards from '@core/slices/pages/awards/awards.vue';
import { Large as AwardsLarge } from '@core/slices/pages/awards/mock.js';

Vue.component('SAwards', SAwards);

export const Figma =
    'https://www.figma.com/design/12sNmCVuCaK0i4MMNUcnsW/Compete-pages?node-id=129-7246&node-type=frame&t=Ike6ltoGltCOaOTl-0';

export const Default = {
    name: 'Default',
    args: {
        translations: {
            competeHeader: {
                title: 'Acronis Cyber Protect Cloud is the best Kaspersky alternative for MSPs',
                lead: 'Your clients all have one thing in common, no matter their industry: <span>data loss and downtime</span> are catastrophic to their operations. As a service provider, the quality of the server backup and recovery services you offer matters.',
                button: {
                    text: 'Schedule your demo',
                },
                comparison: {
                    versusText: 'vs',
                    left: {
                        title: 'Acronis',
                    },
                    right: {
                        title: 'Kaspersky',
                    },
                    features: [
                        { description: 'Placeholder text' },
                        { description: 'Placeholder text' },
                        { description: 'Placeholder text' },
                        { description: 'Placeholder text' },
                        { description: 'Placeholder text' },
                        { description: 'Placeholder text' },
                        { description: 'Placeholder text' },
                    ],
                },
                compareMenu: {
                    title: 'Compare Acronis to:',
                    links: [
                        {
                            text: 'Arcserve',
                        },
                        {
                            text: 'Datto',
                        },
                        {
                            text: 'ESET',
                        },
                        {
                            text: 'Kaspersky',
                        },
                        {
                            text: 'R1Soft',
                        },
                    ],
                },
            },
            topHighlights: {
                title: 'Five reasons clients prefer Acronis over Kaspersky',
                cards: [
                    {
                        title: 'More comprehensive protection across the NIST Framework',
                        description: 'Identify and proactively protect vulnerable assets, detect and respond to threats, and recover from any damage. ',
                    },
                    {
                        title: 'Rightsizes to your and your clients’ needs, budget and size',
                        description: 'Natively integrated cybersecurity, data protection and management — to consolidate, streamline and reduce the cost of your services. ',
                    },
                    {
                        title: 'Minutes-not-hours analysis: guided by AI  ',
                        description: 'Leverage more than the vague interpretations offered by Sophos – unlock the true power of GenAI-guided analysis and response. ',
                    },
                    {
                        title: 'Unmatched business continuity with integrated recovery',
                        description: 'Empower your team to respond more rapidly – with a single-click response to incidents, including built-in recovery, patching and remote access. ',
                    },
                    {
                        title: 'An MSP-first partner, focused on your success ',
                        description: 'Unlike Sophos’ enterprise focus, Acronis has adopted an MSP-first approach: partners can benefit from a program dedicated to their success, rather than competing for their business.',
                    },
                ],
            },
            reviewsSummary: {
                title: 'G2 reviews',
                linkText: 'Read all reviews',
                outOfText: 'out of',
            },
            messageHighlights: {
                title: 'Acronis vs Kaspersky placeholder title',
                bodyText: 'Acronis Disk Director 12.5 delivers a set of powerful tools that work together to optimize your disk usage and protect your data. Store different file systems or operating systems on one disk by creating and managing disk partitions. Acronis Disk Director 12.5 delivers a set of powerful tools that work together to optimize your disk usage and protect your data. Store different file systems or operating systems on one disk.',
                label: 'Label',
                media: {
                    imageAlt: 'Acronis',
                },
            },
            comparisonTables: {
                title: 'Comparison table placeholder text',
                groups: [
                    {
                        head: [
                            { title: 'Easy to use features' },
                            { title: 'Acronis' },
                            { title: 'Kaspersky' },
                        ],
                        rows: [
                            [{ text: 'Listing on Partner Locator' }],
                            [{ text: 'Access to Partner Portal' }, { text: 'Placeholder text' }, { text: 'Placeholder text' }],
                            [{ text: 'Partner Care Team assistance' }],
                            [{ text: 'Partner Account Manager' }],
                            [{ text: 'Partner Account Manager' }, { text: 'Placeholder text' }, { text: 'Placeholder text' }],
                        ],
                    },
                    {
                        head: [
                            { title: 'Security' },
                            { title: 'Acronis' },
                            { title: 'Kaspersky' },
                        ],
                        rows: [
                            [{ text: 'Listing on Partner Locator' }],
                            [{ text: 'Access to Partner Portal' }, { text: 'Placeholder text' }, { text: 'Placeholder text' }],
                            [{ text: 'Partner Care Team assistance' }],
                            [{ text: 'Partner Account Manager' }],
                            [{ text: 'Partner Account Manager' }, { text: 'Placeholder text' }, { text: 'Placeholder text' }],
                        ],
                    },
                    {
                        head: [
                            { title: 'Deployment' },
                            { title: 'Acronis' },
                            { title: 'Kaspersky' },
                        ],
                        rows: [
                            [{ text: 'Listing on Partner Locator' }],
                            [{ text: 'Access to Partner Portal' }, { text: 'Placeholder text' }, { text: 'Placeholder text' }],
                            [{ text: 'Partner Care Team assistance' }],
                            [{ text: 'Partner Account Manager' }],
                            [{ text: 'Partner Account Manager' }, { text: 'Placeholder text' }, { text: 'Placeholder text' }],
                        ],
                    },
                ],
                mobile: {
                    groups: [
                        {
                            title: 'Easy to use features',
                            rows: [
                                [
                                    { text: 'Listing on Partner Locator' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                ],
                                [
                                    { text: 'Access to Partner Portal' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                    { text: 'Placeholder text' },
                                    { text: 'Placeholder text' },
                                ],
                                [
                                    { text: 'Partner Care Team assistance' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                ],
                                [
                                    { text: 'Partner Account Manager' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                ],
                                [
                                    { text: 'Access to Partner Portal' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                    { text: 'Placeholder text' },
                                    { text: 'Placeholder text' },
                                ],
                            ],
                        },
                        {
                            title: 'Easy to use features',
                            rows: [
                                [
                                    { text: 'Listing on Partner Locator' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                ],
                                [
                                    { text: 'Access to Partner Portal' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                    { text: 'Placeholder text' },
                                    { text: 'Placeholder text' },
                                ],
                                [
                                    { text: 'Partner Care Team assistance' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                ],
                                [
                                    { text: 'Partner Account Manager' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                ],
                                [
                                    { text: 'Access to Partner Portal' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                    { text: 'Placeholder text' },
                                    { text: 'Placeholder text' },
                                ],
                            ],
                        },
                        {
                            title: 'Easy to use features',
                            rows: [
                                [
                                    { text: 'Listing on Partner Locator' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                ],
                                [
                                    { text: 'Access to Partner Portal' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                    { text: 'Placeholder text' },
                                    { text: 'Placeholder text' },
                                ],
                                [
                                    { text: 'Partner Care Team assistance' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                ],
                                [
                                    { text: 'Partner Account Manager' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                ],
                                [
                                    { text: 'Access to Partner Portal' },
                                    { text: 'Acronis' },
                                    { text: 'Kaspersky' },
                                    { text: 'Placeholder text' },
                                    { text: 'Placeholder text' },
                                ],
                            ],
                        },
                    ],
                },
            },
            awardsTabs: {
                slices: [
                    {
                        data: {
                            awards: [
                                ...AwardsLarge.args.awards.slice(0, 4).map((el) => ({ title: el.title })),
                            ],
                            title: 'Powered by industry-recognized, award-winning endpoint protection',
                            link: { text: 'See all awards', to: '#' },
                        },
                        label: 'Awards',
                    },
                    {
                        data: {
                            awards: [
                                ...AwardsLarge.args.awards.slice(0, 5).map((el) => ({ title: el.title })),
                            ],
                            title: 'Powered by industry-recognized, award-winning endpoint protection',
                            link: { text: 'See all awards', to: '#' },
                        },
                        label: 'Certifications',
                    },
                ],
            },
            resourceHighlight: {
                title: 'What our partners say',
                cards: [
                    {
                        category: 'Case Study',
                        title: 'Numir switches to Cyber Protect Cloud from Kaspersky and Veritas to consolidate management',
                        imageAlt: 'Alt',
                        text: '"The main benefit of choosing Acronis was the centralization of services such as detection of viruses and malware, updating of operating systems and backing up both PCs and servers"',
                        signature: {
                            text: 'Mr. Gonzalo<br />IT Director, Numir Technologies LLC',
                        },
                        link: {
                            text: 'Read Case Study',
                        },
                    },
                ],
            },
            trialForm: {
                blocks: [
                    {
                        text: 'Hassle-free migration for MSPs at no extra cost',
                    },
                    {
                        description: 'Switch to Acronis Cyber Protect Cloud for a more proven solution and receive 100% of your Acronis usage back until the end of your existing contract term.',
                    },
                ],
            },
            faq: {
                title: 'FAQ',
                replaceWith: 'Kaspersky',
                items: [
                    {
                        list: [
                            {
                                title: 'Is Acronis better than [COMPETITOR NAME]?',
                                content: 'TBD',
                            },
                            {
                                title: 'What makes Acronis Cyber Protect Cloud a better choice than [COMPETITOR NAME]?',
                                content: 'Acronis Cyber Protect is a solution that integrates backup, disaster recovery, AI-based protection against malware and ransomware, remote desktop, and security tools in a single agent.<br />Based on Acronis Hybrid Cloud Architecture, it allows you to protect all the data, whether it is located on-premises, in remote systems, in private and public clouds, or on mobile devices.',
                            },
                            {
                                title: 'Can Acronis Cyber Protect Cloud ensure more reliable data protection compared to [COMPETITOR NAME]?',
                                content: 'Acronis Cyber Protect is a solution that integrates backup, disaster recovery, AI-based protection against malware and ransomware, remote desktop, and security tools in a single agent.<br />Based on Acronis Hybrid Cloud Architecture, it allows you to protect all the data, whether it is located on-premises, in remote systems, in private and public clouds, or on mobile devices.',
                            },
                            {
                                title: 'Does Acronis Cyber Protect Cloud offer any unique features that are not available in [COMPETITOR NAME]?',
                                content: 'Acronis Cyber Protect is a solution that integrates backup, disaster recovery, AI-based protection against malware and ransomware, remote desktop, and security tools in a single agent.<br />Based on Acronis Hybrid Cloud Architecture, it allows you to protect all the data, whether it is located on-premises, in remote systems, in private and public clouds, or on mobile devices.',
                            },
                            {
                                title: 'How do Acronis and [COMPETITOR NAME] compare in terms of innovation and updates?',
                                content: 'Acronis Cyber Protect is a solution that integrates backup, disaster recovery, AI-based protection against malware and ransomware, remote desktop, and security tools in a single agent.<br />Based on Acronis Hybrid Cloud Architecture, it allows you to protect all the data, whether it is located on-premises, in remote systems, in private and public clouds, or on mobile devices.',
                            },
                            {
                                title: 'How does the recovery time following data loss compare between Acronis and [COMPETITOR NAME]?',
                                content: 'Acronis Cyber Protect is a solution that integrates backup, disaster recovery, AI-based protection against malware and ransomware, remote desktop, and security tools in a single agent.<br />Based on Acronis Hybrid Cloud Architecture, it allows you to protect all the data, whether it is located on-premises, in remote systems, in private and public clouds, or on mobile devices.',
                            },
                            {
                                title: 'How do Acronis and [COMPETITOR NAME] compare in terms of cloud backup capabilities?',
                                content: 'Acronis Cyber Protect is a solution that integrates backup, disaster recovery, AI-based protection against malware and ransomware, remote desktop, and security tools in a single agent.<br />Based on Acronis Hybrid Cloud Architecture, it allows you to protect all the data, whether it is located on-premises, in remote systems, in private and public clouds, or on mobile devices.',
                            },
                            {
                                title: 'How do Acronis and [COMPETITOR NAME] compare in terms of ransomware protection capabilities?',
                                content: 'Acronis Cyber Protect is a solution that integrates backup, disaster recovery, AI-based protection against malware and ransomware, remote desktop, and security tools in a single agent.<br />Based on Acronis Hybrid Cloud Architecture, it allows you to protect all the data, whether it is located on-premises, in remote systems, in private and public clouds, or on mobile devices.',
                            }, {
                                title: "How does Acronis's approach to cloud storage management differ from [COMPETITOR NAME]?",
                                content: 'Acronis Cyber Protect is a solution that integrates backup, disaster recovery, AI-based protection against malware and ransomware, remote desktop, and security tools in a single agent.<br />Based on Acronis Hybrid Cloud Architecture, it allows you to protect all the data, whether it is located on-premises, in remote systems, in private and public clouds, or on mobile devices.',
                            },
                        ],
                    },
                ],
            },
        },
        competeHeader: {
            padding: 'a-pt-6',
            button: {
                to: '#',
                type: 'main',
                glyph: 'i-arrow-right--16',
            },
            comparison: {
                left: { score: 7 },
                right: { score: 0 },
                features: [
                    { left: false, right: false },
                    { left: true, right: true },
                    { left: false, right: true },
                    { left: false, right: true },
                    { left: true, right: false },
                    { left: true, right: false },
                    { left: true, right: false },
                ],
            },
            compareMenu: {
                links: [
                    { to: '#' },
                    { to: '#' },
                    { to: '#' },
                    { to: '#', current: true },
                    { to: '#' },
                ],
            },
        },
        topHighlights: {
            padding: 'pt-12 pb-0',
            cards: [
                {
                    image: '@3c63a783ec3fb2ebe8d975194676b889',
                },
                {
                    image: '@3c63a783ec3fb2ebe8d975194676b889',
                },
                {
                    image: '@3c63a783ec3fb2ebe8d975194676b889',
                },
                {
                    image: '@3c63a783ec3fb2ebe8d975194676b889',
                },
                {
                    image: '@3c63a783ec3fb2ebe8d975194676b889',
                },
            ],
        },
        reviewsSummary: {
            padding: 'pt-0',
            g2ProductId: '01faa5b3-7c8a-45ff-a0f3-3cd1bce9332a',
            g2ProductName: 'acronis-cyber-protect-includes-all-features-of-acronis-cyber-backup',
        },
        messageHighlights: {
            padding: 'a-pt-16 a-pb-16',
            labelGlyph: 'coins',
            media: {
                type: 'image',
                link: '@eb8c7302702ae1463ee4559b73bfe08e',
            },
        },
        resourceHighlight: {
            borderBottom: 'full-width',
            cards: [
                {
                    image: '/images/partner_logos/color/lifeboat',
                    imageFitsContainer: false,
                    link: {
                        glyph: 'arrow',
                        to: '#',
                    },
                },
            ],
        },
        awardsTabs: {
            borderBottom: 'full-width',
            useSmallTabs: false,
            sliderOptions: { speed: 300 },
            slices: [
                {
                    data: {
                        awards: [
                            ...AwardsLarge.args.awards.slice(0, 4).map((el) => ({ image: el.image })),
                        ],
                        columns: 4,
                    },
                    anchorId: 'one',
                    name: 's-awards',
                },
                {
                    data: {
                        awards: [...AwardsLarge.args.awards.slice(0, 5).map((el) => ({ image: el.image }))],
                        columns: 5,
                    },
                    name: 's-awards',
                },
            ],
        },
        trialForm: {
            borderBottom: 'full-width',
            form: {
                presetName: 's-preset-form-contact-sales',
                registration: {
                    focusNotSet: true,
                },
            },
            blocks: [
                {
                    name: 'title',
                    withoutIndent: true,
                },
                {
                    name: 'info',
                },
                {
                    name: 'awards',
                    list: [56, 56, 56, 56],
                    hasTitle: true,
                },
            ],
        },
        faq: {
            replaceKey: '[COMPETITOR NAME]',
        },
        comparisonTables: {
            background: 'light-blue',
            groups: [
                {
                    head: [
                        {},
                        { align: 'center' },
                        { align: 'center' },
                    ],
                    rows: [
                        [
                            {},
                            { glyph: 'check-circle' },
                            { glyph: 'close-circle' },
                        ],
                        [],
                        [
                            {},
                            { glyph: 'check-circle' },
                            { glyph: 'close-circle' },
                        ],
                        [
                            {},
                            { glyph: 'check-circle' },
                            { glyph: 'close-circle' },
                        ],
                    ],
                },
                {
                    head: [
                        {},
                        { align: 'center' },
                        { align: 'center' },
                    ],
                    rows: [
                        [
                            {},
                            { glyph: 'check-circle' },
                            { glyph: 'close-circle' },
                        ],
                        [],
                        [
                            {},
                            { glyph: 'check-circle' },
                            { glyph: 'close-circle' },
                        ],
                        [
                            {},
                            { glyph: 'check-circle' },
                            { glyph: 'close-circle' },
                        ],
                    ],
                },
                {
                    head: [
                        {},
                        { align: 'center' },
                        { align: 'center' },
                    ],
                    rows: [
                        [
                            {},
                            { glyph: 'check-circle' },
                            { glyph: 'close-circle' },
                        ],
                        [],
                        [
                            {},
                            { glyph: 'check-circle' },
                            { glyph: 'close-circle' },
                        ],
                        [
                            {},
                            { glyph: 'check-circle' },
                            { glyph: 'close-circle' },
                        ],
                    ],
                },
            ],
            mobile: {
                groups: [
                    {
                        rows: [
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { glyph: 'check-circle' },
                                { glyph: 'close-circle' },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { fontNormal: true },
                                { fontNormal: true },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { glyph: 'check-circle' },
                                { glyph: 'close-circle' },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { glyph: 'check-circle' },
                                { glyph: 'close-circle' },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { fontNormal: true },
                                { fontNormal: true },
                            ],
                        ],
                    },
                    {
                        title: 'Easy to use features',
                        rows: [
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { glyph: 'check-circle' },
                                { glyph: 'close-circle' },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { fontNormal: true },
                                { fontNormal: true },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { glyph: 'check-circle' },
                                { glyph: 'close-circle' },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { glyph: 'check-circle' },
                                { glyph: 'close-circle' },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { fontNormal: true },
                                { fontNormal: true },
                            ],
                        ],
                    },
                    {
                        title: 'Easy to use features',
                        rows: [
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { glyph: 'check-circle' },
                                { glyph: 'close-circle' },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { fontNormal: true },
                                { fontNormal: true },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { glyph: 'check-circle' },
                                { glyph: 'close-circle' },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { glyph: 'check-circle' },
                                { glyph: 'close-circle' },
                            ],
                            [
                                { fullWidth: true },
                                {},
                                {},
                                { fontNormal: true },
                                { fontNormal: true },
                            ],
                        ],
                    },
                ],
            },
        },
    },
};
