import { mapActions, mapState } from 'vuex';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import commonUtils from '@utils/common';
import formHelper from '@utils/form.js';

export default {
    data() {
        return {
            userData: {
                osauid: null,
                first_name: null,
                last_name: null,
                phone: null,
                email: null,
                company: null,
                country: null,
                location: null,
                tenantId: null,
                aan: null,
                requestedContact: false,
            },
            greetModalShown: false,
            gated: false,
            isAvailable: true,
            isPlatformUser: false,
            contactModalShown: false,
            searchParams: null,
        };
    },
    async beforeMount() {
        this.searchParams = new URL(document.location).searchParams;
        await Promise.all([
            this.$store.dispatch('geolocation/getGeoLocation'),
            this.verifyUser(),
        ]);
        const geoCountry = this.$store.state.geolocation.location?.data?.country?.code;
        this.userData.location = geoCountry || this.userData.country;

        if (!this.gated && this.restrictedCountries.includes(geoCountry)) {
            this.isAvailable = false;
        }
    },
    computed: {
        ...mapState({
            pageAudiences: (state) => state.pages?.page.audiences,
            pageCategory: (state) => state.pages?.page.types,
            queryStringData: (state) => state.trial?.queryStringData || {},
            priceLists: (state) => state.vroi?.priceLists || [],
            priceListsMapping: (state) => state.vroi.items?.['s-vroi-price-books'] || {},
        }),
        locale() {
            return this.$route?.params?.locale || LOCALE_DEFAULT;
        },
    },
    methods: {
        ...mapActions({
            createLeadRequest: 'vroi/createLeadRequest',
            updateLeadRequest: 'vroi/updateLeadRequest',
        }),
        adjustPriceList() {
            const country = this.userData.country || this.userData.location;
            const map = this.priceListsMapping;
            const countryMatch = map.countries.find((el) => el.code === country);
            const localeMatch = map.locales.find((el) => el.locale === this.locale);

            this.setPriceList(countryMatch || localeMatch);
        },
        setPriceList(match) {
            this.$store.commit('vroi/setDiscountAfter', match.discountAfter);
            this.$store.commit('vroi/setCurrency', match.currency);
            const priceListId = this.findPriceListId(match.priceBook);
            this.$store.commit('vroi/setCurrentPriceList', {
                id: priceListId,
                name: match.priceBook,
            });
        },
        findPriceListId(name) {
            const priceList = this.priceLists.find((el) => el.name === name);
            return priceList?.id;
        },
        async verifyUser() {
            const osauid = localStorage.getItem('prospect_calculator_osauid');
            const tenantId = localStorage.getItem('prospect_calculator_tenantId') || this.queryStringData.tenantId;

            const hasId = osauid && osauid.length;
            const hasTenantId = tenantId && tenantId.length;

            if (!hasId && !hasTenantId) {
                this.gated = true;
                return;
            }

            if (hasTenantId && !hasId) this.greetModalShown = true;

            this.isPlatformUser = hasTenantId;
            this.gated = false;
            const expectedKeys = new Set([
                'osauid',
                'first_name',
                'last_name',
                'phone',
                'email',
                'company',
                'country',
                'tenantId',
                'aan',
            ]);
            Object.keys(this.userData)
                .filter((x) => expectedKeys.has(x))
                .forEach(this.prefillUserData);

            if (this.userData.osauid) return;
            await this.leadRequest();
        },
        async updateOrCreateUser() {
            try {
                await this.leadRequest('update');
            } catch (error) {
                const status = error.response?.status;
                if (!status || ![409, 404].includes(status)) return;
                await this.leadRequest('create');
            }
        },
        prefillUserData(key) {
            const value = this.queryStringData[key] || localStorage.getItem(`prospect_calculator_${key}`);
            if (!value || value === 'undefined') return;

            this.userData[key] = value;
            localStorage.setItem(`prospect_calculator_${key}`, value);
        },
        formatFormFields() {
            const blacklist = ['globalsDataCenter', 'additional', 'cloudStorageOption'];
            const formFields = Object.keys(this.form).map((key) => {
                if (blacklist.includes(key) || !this.form[key]) return false;
                const field = this.findField(key);
                return {
                    key,
                    value: this.form[key] || this.form.additional[key],
                    label: field?.label,
                };
            }).filter(Boolean);
            const additionalFields = this.additionalInputs.map((key) => ({
                key,
                value: this.form.additional[key],
                label: this.sections.additional.options[key]?.title,
            }));
            return [...formFields, ...additionalFields];
        },
        async leadRequest(action = 'create') {
            const dataCenter = this.dataCenters.find((el) => el.code === this.form.globalsDataCenter);
            const selectedStorageOption = this.findField(this.individualStorageOption);
            const total = this.allOutputs?.total || 0;
            const params = {
                ...this.userData,
                dataCenterLocation: dataCenter?.name,
                selectedStorageOption: selectedStorageOption?.label,
                licensingModel: this.pricingMethod,
                userFromPlatform: this.isPlatformUser,
                fieldsFormatted: this.formatFormFields(),
                form_id: 'PROSPECT_CALCULATOR',
                lead_source: 'Website',
                new_lead_source_detail: 'SP Pricing Calculator',
                product_title: 'Acronis Cyber Protect Cloud',
                calculated_monthly_cost: total,
                isDiscount: this.isDiscount,
                isUnderMinimumCommit: this.isUnderMinimumCommit,
                locale: this.locale,
                currency: this.currency,
                audiences: this.pageAudiences,
                category: this.pageCategory,
                ck: formHelper.getCookies(formHelper.MRKTO_COOKIE_NAME) || formHelper.createMarketoToken(),
                google_analytics_trackingid: formHelper.GA_TRACKING_ID,
                google_analytics_userid: formHelper.getCookies(formHelper.UID_COOKIE_NAME) || '',
                google_analytics_clientid:
                    formHelper.getCIDval() || '[ERROR] Client ID not generated',
                sfdc_campaign_status: this.searchParams.get('sfdc_campaign_status') || '',
                sfdc_campaign_id: '701Hs000001qTA9IAM',
                ...formHelper.labelsUTM(),
            };

            const payload = { ...params, ...formHelper.sfdcCampaign() };

            const updateOrCreate = action === 'update' ? this.updateLeadRequest : this.createLeadRequest;
            const response = await updateOrCreate(payload);
            this.userData.osauid = response.data.data.osauid;
            localStorage.setItem('prospect_calculator_osauid', this.userData.osauid);
        },
        async gatedFormSubmit(payload) {
            Object.keys(payload.data).forEach((key) => {
                this.userData[key] = payload.data[key];
                localStorage.setItem(`prospect_calculator_${key}`, payload.data[key]);
            });
            this.userData.osauid = payload.osauid;
            localStorage.setItem('prospect_calculator_osauid', this.userData.osauid);

            this.gated = payload.gated;
            this.isAvailable = payload.isAvailable;
            await this.init(false);

            formHelper.sendDataLayer({
                eventCategory: 'form',
                eventAction: 'submit_form_success',
                eventLabel: 'vroi calculator gated form',
                // WEB-44946
                formTimer: formHelper.getSecondsOnPage(),
                emailAddress: commonUtils.toSHA256String(this.userData.email), // WEB-45304
            });
        },
        contactAdvisor(e) {
            e.preventDefault();
            this.userData.requestedContact = true;
            this.contactModalShown = true;
            formHelper.sendDataLayer({
                eventCategory: 'Conversions',
                eventAction: 'Contact Acronis Cloud Advisor',
                eventLabel: window.location.href,
            });
        },
        async greetModalClosed() {
            this.greetModalShown = false;
            await this.leadRequest();
            formHelper.sendDataLayer({
                eventCategory: 'Conversions',
                eventAction: this.greetModal.button.text,
                eventLabel: '',
            });
        },
        errorModalClosed() {
            this.isError = false;
            this.gated = true;
        },
    },
};
