<template>
    <div class="event-about">
        <div v-if="location" class="location">
            <a class="event-link location-link" :href="location.to">
                <a-glyph class="left" name="location" />{{ location.label }}
            </a>
            <div class="address">
                {{ location.text }}
            </div>
        </div>
        <div class="body">
            <template v-for="block in descriptionBody.blocks">
                <div v-if="block.type === 'header'" :key="block.id" class="post-title-wrap">
                    <component :is="getTitleElement(block)" class="post-title">
                        <a-dangerous-html :content="block.data.text" tag="span" />
                    </component>
                </div>
                <p v-else-if="block.type === 'paragraph'" :key="block.id" class="post-paragraph">
                    <a-dangerous-html :content="block.data.text" tag="span" />
                </p>
                <component
                    :is="getListElement(block)"
                    v-else-if="block.type === 'list'"
                    :key="block.id"
                    class="post-list"
                >
                    <li v-for="(listItem, j) in block.data.items" :key="j" class="post-list-item">
                        <a-dangerous-html :content="listItem" tag="span" />
                    </li>
                </component>
            </template>
        </div>
        <div class="socials">
            <div class="socials-title">
                {{ socialTitle }}
            </div>
            <a-social class="links" :social="socials" />
        </div>
    </div>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ASocial from '@core/components/social/social.vue';

export default {
    name: 'EventAbout',

    components: {
        AGlyph,
        ASocial,
        ADangerousHtml,
    },
    props: {
        location: {
            type: Object,
            default: () => null,
        },
        socials: {
            type: Array,
            required: true,
        },
        socialTitle: {
            type: String,
            required: true,
        },
        body: {
            type: String,
            required: true,
        },
    },

    computed: {
        descriptionBody() {
            return JSON.parse(this.body);
        },
    },

    methods: {
        getTitleElement(block) {
            const level = Math.max(block.data?.level || 2, 2);
            return `h${level}`;
        },

        getListElement(block) {
            return block?.data?.style === 'ordered' ? 'ol' : 'ul';
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-about {
    .location {
        margin-bottom: 24px;
    }

    .location-link {
        color: var(--av-brand-secondary);
        margin-bottom: 8px;
        font-weight: 700;
    }

    .address {
        color: var(--av-fixed-light);
    }

    .socials {
        display: flex;
        align-items: center;
    }

    .socials-title {
        color: var(--av-nav-primary);
        font-size: 11px;
        line-height: 16px;
        font-weight: 700;
        margin-inline-end: 8px;
    }
}

.body {
    overflow-wrap: anywhere;
    color: var(--av-fixed-primary);
    margin-bottom: 38px;
    text-align: start;

    .post-title {
        margin-top: 0;
        color: var(--av-nav-primary);
        font-weight: 400;

        &:is(h2) {
            @mixin display;

            margin-bottom: 16px;
        }

        &:is(h3) {
            @mixin title;

            margin-bottom: 16px;
        }

        &:is(h4, h5, h6) {
            @mixin lead-accent;

            margin-bottom: 8px;
        }
    }

    .post-paragraph {
        @mixin paragraph;
        color: var(--av-fixed-primary);
        margin-bottom: 24px;
    }

    .post-list {
        padding-inline-start: 24px;

        &:is(ol) {
            margin-bottom: 24px;
            list-style-type: decimal;

            .post-list-item {
                margin-bottom: 16px;
            }
        }

        &:is(ul) {
            margin-bottom: 24px;

            .post-list-item {
                position: relative;
                margin-bottom: 16px;

                &::before {
                    content: '';
                    display: inline-block;
                    height: 4px;
                    width: 4px;
                    background: var(--av-fixed-secondary);
                    margin: 0 10px 0 10px;
                    position: absolute;
                    inset-inline-start: -24px;
                    top: 10px;
                    border-radius: 2px;
                }
            }
        }
    }
}
</style>

<style lang="postcss">
.event-about {
    .a-dangerous-html {
        i {
            font-style: italic;
        }

        b {
            font-weight: bold;
        }
    }

    .a-social {
        &__link {
            display: flex;
            align-items: center;
            height: 100%;
        }

        &__icon {
            height: 16px;
            width: 16px;
            fill: var(--av-brand-secondary);
        }
    }
}
</style>
