<template>
    <div class="a-video-card" :class="className">
        <template v-if="oneTrustConsentWasGiven">
            <div v-modal="modalId" class="a-video-card__wrapper">
                <a-picture
                    class="a-video-card__img"
                    :link="image"
                    :alt="imageAlt"
                    fit="fill"
                    is-background
                />
                <div class="a-video-card__play">
                    <a-glyph name="play" />
                </div>
                <div class="a-video-card__content">
                    <v-clamp class="a-video-card__text" autoresize :max-lines="1">
                        {{ text }}
                    </v-clamp>
                    <span v-if="time" class="a-video-card__time">
                        {{ time }}
                    </span>
                </div>
            </div>
            <a-modal
                :id="modalId"
                type="youtube"
                is-youtube
            >
                <a-youtube
                    :yt-id="ytId"
                    :params="ytParams"
                    :title="text"
                    @progressChanged="$emit('progressChanged', $event)"
                />
            </a-modal>
        </template>
        <template v-else>
            <div class="a-video-card__wrapper" @click="openYouTubePage">
                <a-picture
                    class="a-video-card__img"
                    :link="image"
                    :alt="imageAlt"
                    fit="fill"
                    is-background
                />
                <div class="a-video-card__play">
                    <a-glyph name="play" />
                </div>
                <div class="a-video-card__content">
                    <v-clamp class="a-video-card__text" autoresize :max-lines="1">
                        {{ text }}
                    </v-clamp>
                    <span v-if="time" class="a-video-card__time">
                        {{ time }}
                    </span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import AGlyph from '@core/components/glyph/glyph.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';
import { SIZES } from './constants.js';

const ONETRUST_CONSENT_CHANGED = 'consent.onetrust';

export default {
    name: 'AVideoCard',

    components: {
        AYoutube,
        VClamp,
        AGlyph,
        AModal,
        APicture,
    },

    directives: {
        Modal,
    },

    mixins: [breakpoint],

    props: {
        /**
         * Youtube id
         */
        ytId: {
            type: String,
            required: true,
        },

        ytParams: {
            type: Object,
            default: () => ({ autoplay: 1 }),
        },

        /**
         * Text
         */
        text: {
            type: String,
            default: undefined,
        },

        /**
         * Video time
         */
        time: {
            type: String,
            default: undefined,
        },

        /**
         * Image URL
         */
        image: {
            type: String,
            required: true,
        },

        /**
         * Image alt
         */
        imageAlt: {
            type: String,
            default: undefined,
        },

        /**
         * Size
         */
        size: {
            type: String,
            default: undefined,
            validator: (value) => value === undefined || SIZES.includes(value),
        },
    },

    emits: ['progressChanged'],

    data: () => ({
        oneTrustConsentWasGiven: false,
    }),

    computed: {
        modalId() {
            return `yt_id-${this.ytId}`;
        },
        computedSize() {
            if (this.size) return this.size;
            return this.isMobile ? 'small' : 'large';
        },
        className() {
            const { computedSize } = this;
            return {
                [`a-video-card_size_${computedSize}`]: computedSize,
                'a-video-card--no-overlay': !this.text,
            };
        },
        youtubeUrl() {
            return `https://www.youtube.com/watch?v=${this.ytId}`;
        },
    },

    mounted() {
        this.checkOneTrustConsent();
        window.addEventListener(ONETRUST_CONSENT_CHANGED, this.checkOneTrustConsent);
    },

    beforeDestroy() {
        window.removeEventListener(ONETRUST_CONSENT_CHANGED, this.checkOneTrustConsent);
    },

    methods: {
        checkOneTrustConsent() {
            if (typeof OptanonActiveGroups === 'undefined') return;
            // eslint-disable-next-line no-undef
            this.oneTrustConsentWasGiven = OptanonActiveGroups.includes('C0004');
        },

        openYouTubePage() {
            window.open(this.youtubeUrl, '_blank');
        },
    },
};
</script>

<style lang="postcss">
.a-video-card {
    width: 100%;
    aspect-ratio: 16 / 9;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid var(--av-brand-secondary-light, rgba(64, 139, 234, 0.3));
    box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
    transition: all 0.2s ease-in-out;

    &_size {
        &_small {
            .a-video-card__play {
                padding: 16px;
                width: 56px;
                height: 56px;

                .a-glyph {
                    width: 24px;
                    height: 24px;
                }
            }
            .a-video-card__text {
                @mixin caption;
            }
        }

        &_large {
            .a-video-card__play {
                padding: 20px;
                width: 72px;
                height: 72px;

                .a-glyph {
                    width: 32px;
                    height: 32px;
                }
            }
            .a-video-card__text {
                @mixin paragraph;
            }
        }
    }

    &__wrapper {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: '';
            position: absolute;
            border: var(--av-border);
            border-radius: 4px;
            top: 0;
            inset-inline-start: 0;
            bottom: 0;
            inset-inline-end: 0;
            z-index: 6;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: transform 0.2s ease-in-out;

        &:before {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(192deg, rgba(0, 49, 127, 0) 48.94%, rgba(0, 49, 127, 0.8) 100%);
            z-index: 1;
            opacity: 1;
            pointer-events: none;
            will-change: opacity;
            transition: opacity 0.2s ease-in-out;
        }

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(192deg, rgba(0, 91, 204, 0) 48.94%, #005BCC 100%);
            z-index: 2;
            opacity: 0;
            pointer-events: none;
            will-change: opacity;
            transition: opacity 0.2s ease-in-out;
        }
    }

    &:hover,
    &:active {
        box-shadow: none;
        transition: box-shadow 0.2s ease-in-out;

        .a-video-card {
            &__img {
                transform: scale(1.02);

                &:before {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &__play {
                background-color: #0065e3;
                box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);

                &:hover {
                    background-color: #005bcc;
                }
            }
        }
    }

    &:active {
        .a-video-card__play {
            background-color: #0051b6;

            &:active {
                background-color: #0051b6;
            }
        }
    }

    &__content {
        position: absolute;
        z-index: 1;
        bottom: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        width: 100%;
        padding: 0 8px 8px 16px;
        height: 80%;
        display: flex;
        align-items: flex-end;
    }

    &__play {
        position: absolute;
        width: 56px;
        height: 56px;
        background: #0065e3;
        border-radius: 50px;
        display: flex;
        transition: all ease-in-out 0.2s;
        flex-shrink: 0;
        z-index: 5;

        .a-glyph {
            fill: var(--av-inversed-primary);
            margin: auto;
            width: 24px;
            height: 24px;
        }
    }

    &__text {
        @mixin caption;
        color: var(--av-inversed-primary);
        font-weight: 600;
        padding-inline-end: 24px;
        margin-bottom: 8px;
        flex: 1;
    }

    &__time {
        @mixin caption;
        color: var(--av-inversed-primary);
        background: var(--av-fixed-lighter);
        border-radius: 4px;
        padding: 0 2px;
    }

    &--no-overlay {
        .a-video-card__img {
            &:before,
            &:after {
                display: none;
            }
        }
    }
}
</style>
