<template>
    <div class="event-menu" :class="className">
        <client-only>
            <div class="content-wrap" :class="{ 'menu-opened': mobileMenuOpened }">
                <event-container>
                    <div class="content">
                        <a class="event-link logo-wrap" :class="{ small: useSmallLogo }" href="/">
                            <div class="logo">
                                <a-logo type="full" />
                            </div>
                            <a-picture
                                v-if="extraLogo"
                                class="extra-logo"
                                :link="extraLogo"
                                size="normal"
                                fit="cover"
                            />
                            <div v-else class="event-title">{{ eventsTitle }}</div>
                        </a>
                        <div v-if="links && !dropdownMenuVisible" class="links">
                            <a
                                v-for="(link, i) in enabledLinks"
                                :key="i"
                                class="event-link link"
                                :href="link.to"
                                :class="{ active: link.to === activeLink }"
                                :data-text="link.text"
                                @click.prevent="linkClick(link.to)"
                            >
                                <div class="link-content">{{ link.text }}</div>
                            </a>
                        </div>
                        <div v-if="showLocaleSelector" class="locale-select" :class="{ 'with-menu': dropdownMenuVisible }">
                            <slot name="locale-select" />
                        </div>
                        <div v-if="dropdownMenuVisible" class="mobile-links-button" @click="toggleMobileMenu">
                            <a-glyph :name="mobileMenuGlyph" />
                        </div>
                    </div>
                </event-container>
                <div v-if="dropdownMenuVisible" class="mobile-links-wrap" :class="{ opened: mobileMenuOpened }">
                    <div class="mobile-links">
                        <a
                            v-for="(link, i) in enabledLinks"
                            :key="i"
                            class="mobile-link"
                            :href="link.to"
                            :class="{ active: link.to === activeLink }"
                            @click.stop.prevent="mobileLinkClick(link.to)"
                        >
                            {{ link.text }}
                        </a>
                    </div>
                </div>
            </div>
        </client-only>
    </div>
</template>

<script>
import ClientOnly from '@core/components/client-only/client-only.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALogo from '@core/components/logo/logo.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import EventContainer from '../shared-components/event-container/event-container.vue';

export default {
    name: 'EventMenu',

    components: {
        ClientOnly,
        AGlyph,
        APicture,
        ALogo,
        EventContainer,
    },

    mixins: [breakpoint],
    props: {
        extraLogo: {
            type: String,
            default: null,
        },
        eventsTitle: {
            type: String,
            default: null,
        },
        theme: {
            type: String,
            default: 'light',
            validator: (value) => !value || ['light', 'dark'].includes(value),
        },
        layout: {
            type: String,
            default: null,
        },
        links: {
            type: Array,
            default: () => [],
        },
        showLocaleSelector: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            activeLink: null,
            mobileMenuOpened: false,
            scrolling: false,
            SCROLL_MARGIN: 5,
        };
    },

    computed: {
        className() {
            return {
                dark: this.theme === 'dark',
                'in-event-header': this.layout === 'event',
            };
        },

        useSmallLogo() {
            return Boolean(this.links);
        },

        dropdownMenuVisible() {
            const showMobileMenu = this.currentBreakpoint !== 'desktopLarge';
            return this.links.length > 0 && showMobileMenu;
        },

        enabledLinks() {
            return this.links.filter((x) => x.enabled);
        },

        mobileMenuGlyph() {
            return this.mobileMenuOpened ? 'close' : 'burger-menu';
        },
    },

    mounted() {
        window.addEventListener('scroll', this.setActiveLink);
        window.addEventListener('resize', this.setActiveLink);
        this.setActiveLink();
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.setActiveLink);
        window.removeEventListener('resize', this.setActiveLink);
    },

    methods: {
        mobileLinkClick(link) {
            this.linkClick(link);
            this.mobileMenuOpened = false;
        },

        linkClick(link) {
            if (!link.includes('#')) {
                window.location = link;
                return;
            }

            const el = document.querySelector(link);

            if (!el) return;

            window.history.pushState({}, '', link);

            if ('scrollBehavior' in document.documentElement.style) {
                this.activeLink = link;
                this.scrolling = true;

                this.scrollTo(
                    el.getBoundingClientRect().top + window.scrollY - this.$el.offsetHeight,
                    () => {
                        this.scrolling = false;
                        this.setActiveLink();
                    },
                );
            }
        },

        scrollTo(offset, callback) {
            window.scroll({
                top: offset,
                behavior: 'smooth',
            });

            let position = null;
            const checkIfScrollIsStatic = setInterval(() => {
                if (position === window.scrollY) {
                    clearInterval(checkIfScrollIsStatic);
                    callback();
                }
                position = window.scrollY;
            }, 25);
        },

        setActiveLink() {
            if (!this.links || this.scrolling) {
                return;
            }

            const scrolled = this.links.filter((item) => {
                if (!item.to.includes('#')) return false;

                try {
                    const sectionEl = document.querySelector(item.to);
                    return window.pageYOffset >= sectionEl.offsetTop - this.$el.offsetHeight - this.SCROLL_MARGIN;
                } catch {
                    return false;
                }
            });

            const last = scrolled.length ? scrolled.pop() : this.links[0];

            this.activeLink = last ? last.to : null;
        },

        toggleMobileMenu() {
            this.mobileMenuOpened = !this.mobileMenuOpened;
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-menu {
    z-index: 123; /* above event form */
    height: 80px;
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;

    &.is-sticky {
        position: fixed;
        z-index: 5;
        background: var(--av-inversed-primary);
    }

    &.is-sticky .content-wrap,
    .content-wrap.menu-opened {
        z-index: 5;
        box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
    }

    &.dark {
        border-bottom: 2px solid var(--av-brand-light);
        background: transparent;

        @media (--viewport-desktop) {
            border-bottom: 0;
        }

        .event-title {
            font-weight: 200;
        }

        .event-title,
        .locale-select {
            color: var(--av-inversed-primary);
        }

        .extra-logo {
            &::before {
                background: var(--av-inversed-primary);
            }
        }

        .locale-select-glyph {
            fill: var(--av-inversed-primary);
        }
    }

    &.in-event-header {
        position: absolute;
        inset-inline-start: 0;
        inset-inline-end: 0;
        margin: auto;
        height: auto;
        top: 0;
    }

    .content-wrap {
        position: relative;
        height: 100%;
        z-index: 5;
    }

    .content {
        display: flex;
        align-items: center;
        height: 78px;
    }

    .logo-wrap {
        position: relative;
        display: flex;
        text-decoration: none;
        color: var(--av-nav-primary);
        margin-inline-end: auto;

        &.small {
            .event-title {
                top: 0;
                font-size: 32px;
            }
        }
    }

    .logo {
        display: flex;
        align-items: center;
    }

    .extra-logo {
        position: relative;
        height: 60px;
        max-width: 128px;
        margin-inline-start: 48px;
        display: flex;
        align-items: center;

        &::before {
            position: absolute;
            top: 0;
            inset-inline-start: -24px;
            content: '';
            width: 1px;
            height: 100%;
            background: var(--av-nav-primary);
            opacity: 0.5;
        }
    }

    .event-title {
        position: relative;
        top: -1px;
        font-size: 38px;
        line-height: 30px;
        font-weight: 300;
        color: var(--av-nav-primary);
        margin-inline-start: 8px;
    }

    .links {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        flex-grow: 1;
        position: relative;
        z-index: 2;
    }

    .link {
        position: relative;
        padding: 0 12px;
        text-decoration: none;
        @mixin paragraph;
        color: var(--av-nav-primary);

        &.active,
        &:hover {
            font-weight: 700;
            color: var(--av-nav-primary);
        }

        &::before {
            position: relative;
            visibility: hidden;
            font-weight: 700;
            content: attr(data-text);
        }

        .link-content {
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            margin: auto;
        }
    }

    .mobile-links-wrap {
        overflow: hidden;
        max-height: 0;
        transition: max-height ease 0.15s;
        position: absolute;
        top: 100%;
        background: var(--av-inversed-primary);
        inset-inline-start: 0;
        inset-inline-end: 0;
        box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);

        &.opened {
            max-height: 1000px;
        }
    }

    .mobile-links {
        padding: 16px 0 48px;
    }

    .mobile-link {
        display: block;
        padding: 16px 16px;
        color: var(--av-nav-primary);
        text-decoration: none;
        @mixin display;

        @media (--viewport-tablet) {
            padding: 16px 32px;
        }

        &.active {
            font-weight: 700;
        }
    }

    .mobile-links-button {
        margin-inline-start: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;

        @media (--viewport-tablet) {
            margin-inline-start: 24px;
        }
    }

    .event-container {
        overflow: visible;
    }

    .logo-wrap {
        &:deep(.a-logo) {
            width: 127px;
        }

        &.small {
            &:deep(.a-logo) {
                width: 111px;
            }
        }
    }
    .mobile-links-button {
        .a-glyph {
            width: 24px;
            height: 24px;
            fill: var(--av-nav-primary);
        }
    }

    .locale-select.with-menu:deep(.popup) {
        margin-inline-end: -34px;
        @media (--viewport-tablet) {
            margin-inline-end: -40px;
        }
        @media (--viewport-desktop) {
            margin-inline-end: 0;
        }
    }
}

.event-menu.dark {
    &.is-sticky {
        background: transparent;
    }

    .event-title {
        color: var(--av-inversed-primary);
    }

    .logo-wrap .a-logo {
        fill: var(--av-inversed-primary);
    }

    .event-link {
        color: var(--av-inversed-primary);

        &.active {
            font-weight: 700;
        }
    }

    &:deep(.locale-button) {
        background: var(--av-inversed-invisible);
        color: var(--av-inversed-primary);

        .a-glyph {
            fill: var(--av-inversed-primary);
            margin: 0;

            @media (--viewport-tablet) {
                margin-inline-start: 8px;
            }
        }
    }

    .mobile-links-button {
        .a-glyph {
            fill: var(--av-inversed-primary);
        }
    }
}

.event-menu.dark.event-home-menu {
    .content-wrap {
        background: var(--av-nav-primary);
    }
}

</style>
